import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSessionStorage, setSessionStorage } from 'src/common/storage';
import { Area, KeyValue, Reservation, Table } from 'src/pages/host-view/types';
import { HostViewEnum, ResStatusEnum, resStatusToHostViewStatusMap, StatusEnum } from 'src/pages/host-view/types/enums';

export enum ViewType {
	LIST = 'list',
	TABLES = 'tables',
}

export enum HostViewFiltersEnum {
	Date = 'date',
	Time = 'time',
	Location = 'location',
	Source = 'source',
	Upcoming = 'upcoming',
}

export interface OverviewDetails {
	waiTime: number;
	guests: number;
	availability: number;
	inUseTables: number;
	tables: number;
}

export interface HostViewFilters {
	[HostViewFiltersEnum.Date]: KeyValue | null;
	[HostViewFiltersEnum.Time]: KeyValue | null;
	[HostViewFiltersEnum.Location]: KeyValue | null;
	[HostViewFiltersEnum.Source]?: KeyValue | null;
	[HostViewFiltersEnum.Upcoming]?: KeyValue | null;
}

interface HostViewState {
	view: ViewType;
	width: number;
	searchQuery: string;
	openSidebar: boolean;
	mobileAssign: boolean;
	filters: HostViewFilters;
	activeTab: HostViewEnum;
	activeArea: string | null;
	statuses: StatusEnum[];
	areas: Area[];
	reservations: Reservation[];
	upcomingReservations: Reservation[];
	assignReservation: string | null;
	assignTables: string[];
}

const sessionHostViewFilters = JSON.parse(getSessionStorage('host-view-filters'));

const initialState: HostViewState = {
	view: ViewType.TABLES,
	statuses: [],
	searchQuery: '',
	activeArea: null,
	openSidebar: false,
	mobileAssign: false,
	width: window.innerWidth,
	activeTab: HostViewEnum.Reservation,
	filters: {
		[HostViewFiltersEnum.Date]: sessionHostViewFilters
			? sessionHostViewFilters[HostViewFiltersEnum.Date]
			: { key: String(new Date()), value: String(new Date()) },
		[HostViewFiltersEnum.Time]: sessionHostViewFilters ? sessionHostViewFilters[HostViewFiltersEnum.Time] : null,
		[HostViewFiltersEnum.Location]: sessionHostViewFilters ? sessionHostViewFilters[HostViewFiltersEnum.Location] : null,
	},
	areas: [],
	reservations: [],
	assignTables: [],
	upcomingReservations: [],
	assignReservation: null,
};

const hostViewSlice = createSlice({
	name: 'hostView',
	initialState,
	reducers: {
		setSidebarOpen: (state) => {
			state.openSidebar = !state.openSidebar;
		},
		setSearchQuery: (state, action: PayloadAction<string>) => {
			state.searchQuery = action.payload;
		},
		setView: (state, action: PayloadAction<ViewType>) => {
			state.view = action.payload;
		},
		setMobileAssign: (state, action: PayloadAction<boolean>) => {
			state.mobileAssign = action.payload;
		},
		setWidth: (state, action: PayloadAction<number>) => {
			state.width = action.payload;
		},
		setStatuses: (state, action: PayloadAction<StatusEnum[]>) => {
			state.statuses = action.payload;
		},
		setActiveArea: (state, action: PayloadAction<string | null>) => {
			state.activeArea = action.payload;
		},
		setActiveTab: (state, action: PayloadAction<HostViewEnum>) => {
			state.activeTab = action.payload;
		},
		setFilters: (state, action: PayloadAction<HostViewFilters>) => {
			const stringifiedJson = JSON.stringify(action.payload);
			if (JSON.stringify(state.filters) !== stringifiedJson) {
				setSessionStorage('host-view-filters', stringifiedJson);
			}
			state.filters = action.payload;
		},
		setAreas: (state, action: PayloadAction<Area[]>) => {
			state.areas = action.payload;
		},
		setReservations: (state, action: PayloadAction<{ reservations: Reservation[]; id?: string }>) => {
			const { id, reservations } = action.payload;

			if (id) {
				state.reservations = state.reservations.map((reservation) =>
					reservation._id === id ? { ...reservation, ...reservations.find((r) => r._id === id) } : reservation
				);
				return;
			}

			state.reservations = reservations;
		},
		setUpcomingReservations: (state, action: PayloadAction<Reservation[]>) => {
			state.upcomingReservations = action.payload;
		},
		setAssignTables: (state, action: PayloadAction<string[]>) => {
			state.assignTables = action.payload;
		},
		setAssignReservation: (state, action: PayloadAction<string | null>) => {
			state.assignReservation = action.payload;
		},
	},
});

export const {
	setView,
	setWidth,
	setSearchQuery,
	setSidebarOpen,
	setActiveArea,
	setActiveTab,
	setStatuses,
	setFilters,
	setAreas,
	setMobileAssign,
	setReservations,
	setAssignTables,
	setAssignReservation,
	setUpcomingReservations,
} = hostViewSlice.actions;

export const selectView = (state: { hostView: HostViewState }) => state.hostView.view;
export const selectWidth = (state: { hostView: HostViewState }) => state.hostView.width;
export const selectAreas = (state: { hostView: HostViewState }) => state.hostView.areas;
export const selectFilters = (state: { hostView: HostViewState }) => state.hostView.filters;
export const selectStatuses = (state: { hostView: HostViewState }) => state.hostView.statuses;
export const selectActiveTab = (state: { hostView: HostViewState }) => state.hostView.activeTab;
export const selectActiveArea = (state: { hostView: HostViewState }) => state.hostView.activeArea;
export const selectOpenSidebar = (state: { hostView: HostViewState }) => state.hostView.openSidebar;
export const selectSearchQuery = (state: { hostView: HostViewState }) => state.hostView.searchQuery;
export const selectMobileAssign = (state: { hostView: HostViewState }) => state.hostView.mobileAssign;
export const selectReservations = (state: { hostView: HostViewState }) => state.hostView.reservations;
export const selectAssignTables = (state: { hostView: HostViewState }) => state.hostView.assignTables;
export const selectAssignReservation = (state: { hostView: HostViewState }) => state.hostView.assignReservation;
export const selectUpcomingReservations = (state: { hostView: HostViewState }) => state.hostView.upcomingReservations;

const filterReservations = createSelector([selectReservations, selectSearchQuery], (reservations, searchQuery) => {
	return reservations.filter((res) => {
		const { fname, lname, phone } = res.host;
		return (
			searchQuery.length < 3 ||
			(fname && fname.toLowerCase().includes(searchQuery.toLowerCase())) ||
			(lname && lname.toLowerCase().includes(searchQuery.toLowerCase())) ||
			(phone && phone.toLowerCase().includes(searchQuery.toLowerCase()))
		);
	});
});

export const selectUpcomingReserved = createSelector([filterReservations], (filteredReservations) => {
	const currentTime = new Date().getTime();

	const upcoming = filteredReservations
		.filter((reservation) => {
			const reservationDate = new Date(reservation.date.slice(0, -1));

			return (
				reservationDate.getTime() > currentTime &&
				(reservation.status === ResStatusEnum.Confirmed || reservation.status === ResStatusEnum.Pending)
			);
		})
		.sort((a, b) => {
			const timeA = new Date(a.date);
			const timeB = new Date(b.date);

			if (timeA.getTime() === timeB.getTime()) {
				return a.tables ? 1 : -1;
			}
			return timeA.getTime() - timeB.getTime();
		});

	const upcomingPeople = upcoming.reduce((sum, reservation) => sum + (reservation.people || 0), 0);

	return {
		upcoming,
		upcomingPeople,
		upcomingTables: upcoming.length,
	};
});

export const selectSeatedReserved = createSelector([filterReservations], (filteredReservations) => {
	const seated = filteredReservations
		.filter((reservation) => reservation.status === ResStatusEnum.Arrived)
		.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

	const seatedPeople = seated.reduce((sum, reservation) => sum + (reservation.people || 0), 0);

	return {
		seated,
		seatedPeople,
		seatedTables: seated.length,
	};
});

const selectActiveReservations = createSelector([selectReservations], (reservations) =>
	reservations.filter((res) => res.status !== ResStatusEnum.Completed)
);

const selectReservedTablesMap = createSelector([selectActiveReservations], (activeReservations) => {
	const reservedTablesMap = new Map();

	activeReservations.forEach((res) => {
		res.tables.forEach((table) => {
			if (!reservedTablesMap.has(table._id)) {
				reservedTablesMap.set(table._id, []);
			}
			reservedTablesMap.get(table._id).push(res);
		});
	});

	return reservedTablesMap;
});

const sortByStatus = (a: Table, b: Table) => {
	const statusOrder = {
		[StatusEnum.Available]: 1,
		[StatusEnum.Reserved]: 2,
		[StatusEnum.Occupied]: 3,
	};
	return statusOrder[a.status] - statusOrder[b.status];
};

const sortByDate = (a: Reservation, b: Reservation) => {
	return new Date(a.date).getTime() - new Date(b.date).getTime();
};

export const selectTables = (disableFilters = false, disableArea = false, selectedArea = '') =>
	createSelector(
		[selectAreas, selectActiveArea, selectStatuses, selectReservedTablesMap, selectUpcomingReservations],
		(areas, activeArea, activeStatuses, reservedTablesMap, upcomingReservations) => {
			const updateTableStatus = (table: Table) => {
				const reservations = reservedTablesMap.get(table._id) || [];

				if (!reservations.length) {
					const tableUpcomingReservations = upcomingReservations.filter((reservation) =>
						reservation.tables.some((t) => t._id === table._id)
					);
					tableUpcomingReservations.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

					return {
						...table,
						date: tableUpcomingReservations[0]?.date,
						res_id: null,
						duration: null,
						preferences: null,
						guest: null,
						people: `${table.min_seats}-${table.max_seats}`,
						status: StatusEnum.Available,
					};
				}

				reservations.sort(sortByDate);
				const currentReservation = reservations[0];
				return {
					...table,
					date: currentReservation.date,
					res_id: currentReservation._id,
					people: currentReservation.people,
					duration: currentReservation.duration_mins,
					preferences: currentReservation.preferences,
					guest: `${currentReservation.host.fname} ${currentReservation.host.lname}`,
					status: resStatusToHostViewStatusMap[currentReservation.status] ?? StatusEnum.Reserved,
				};
			};

			const filterByActiveStatus = (table) => disableFilters || !activeStatuses.length || activeStatuses.includes(table.status);

			const filterAndUpdateTables = (tables) => tables.map(updateTableStatus).filter(filterByActiveStatus).sort(sortByStatus);

			const areaId = disableArea ? selectedArea : activeArea;
			if (areaId) {
				const selectedTables = areas.find((area) => area._id === areaId)?.tables || [];
				return filterAndUpdateTables(selectedTables);
			}

			return areas.flatMap((area) => filterAndUpdateTables(area.tables));
		}
	);

export const selectStatusDetails = createSelector([selectTables(true, false)], (tables: Table[]) =>
	tables.reduce(
		(details, { status }) => {
			if (status === StatusEnum.Available) details.available += 1;
			else if (status === StatusEnum.Reserved) details.reserved += 1;
			else if (status === StatusEnum.Occupied) details.occupied += 1;

			return details;
		},
		{ available: 0, reserved: 0, occupied: 0 }
	)
);

export const selectOverviewDetails = createSelector([selectTables(true, true)], (tables: Table[]): OverviewDetails => {
	const details: OverviewDetails = {
		waiTime: 30,
		guests: 0,
		availability: 0,
		inUseTables: 0,
		tables: tables.length ?? 0,
	};

	const totalAvailableTables = tables.reduce(
		(acc, table) => {
			if (table.status === StatusEnum.Available) {
				acc.available++;
			} else {
				acc.inUse++;
				if (table.status === StatusEnum.Reserved || table.status === StatusEnum.Occupied) {
					acc.guests += table.people || 0;
				}
			}
			return acc;
		},
		{ available: 0, inUse: 0, guests: 0 }
	);

	details.inUseTables = totalAvailableTables.inUse;
	details.guests = totalAvailableTables.guests;

	if (details.tables > 0) {
		details.availability = 100 - Math.round((totalAvailableTables.available / details.tables) * 100);
	}

	return details;
});

export const selectAssignRes = createSelector([selectReservations, selectAssignReservation], (reservations, assignResId) => {
	const reservationToAssign = reservations.find((res) => res._id === assignResId);
	return reservationToAssign;
});

const isSameReservationTime = (resToAssign, reservedTable) => {
	if (!reservedTable) return false;
	const resStartTime = new Date(resToAssign.date).getTime();
	const reservedStartTime = new Date(reservedTable.date).getTime();
	return reservedStartTime === resStartTime;
};

export const selectIsTableAssignable = (tableId: string) =>
	createSelector(
		[selectAssignRes, selectAssignTables, selectReservedTablesMap, selectAreas, selectActiveArea],
		(resToAssign, assignTables, reservedTablesMap, areas, activeArea) => {
			if (!resToAssign) {
				return false;
			}
			if (assignTables.includes(tableId)) {
				return true;
			}
			if (isSameReservationTime(resToAssign, reservedTablesMap.get(tableId))) {
				// TODO: Number(resToAssign.duration_mins) * 60 * 1000
				return false;
			}

			const totalPeople =
				areas
					.find((area) => area._id === activeArea)
					?.tables.reduce((acc, table) => (assignTables.includes(table._id) ? acc + table.max_seats : acc), 0) || 0;

			return resToAssign.people > totalPeople;
		}
	);

export const selectEnableSuccessAssignBtn = createSelector(
	[selectAreas, selectActiveArea, selectAssignRes, selectAssignTables],
	(areas, activeArea, resToAssign, assignTables) => {
		const selActiveArea = areas.find((area) => area._id === activeArea);
		const tablesInActiveArea = selActiveArea?.tables.filter((table) => assignTables.includes(table._id));
		const maxSeatsInActiveArea = (tablesInActiveArea ?? []).reduce((total, table) => total + table.max_seats, 0);
		const minSeatsInActiveArea = (tablesInActiveArea ?? []).reduce((total, table) => total + table.min_seats, 0);

		const isAssignable = Boolean(resToAssign?.people) && (resToAssign?.people ?? 0) <= maxSeatsInActiveArea;

		let assignMessage;
		if (!((resToAssign?.people ?? 0) >= minSeatsInActiveArea && (resToAssign?.people ?? 0) <= maxSeatsInActiveArea)) {
			if ((resToAssign?.people ?? 0) >= maxSeatsInActiveArea) {
				assignMessage = 'Not enough seats selected';
			} else if ((resToAssign?.people ?? 0) < maxSeatsInActiveArea) {
				assignMessage = 'Table(s) seats exceed reserved';
			}
		}

		return {
			isAssignable,
			assignMessage,
		} as {
			isAssignable: boolean;
			assignMessage?: string;
		};
	}
);

export default hostViewSlice.reducer;
