import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appSlice from 'src/reducers/app/slice';

import areasReducer from '../areas/slice';
import authReducer from '../auth/slice';
import businessReducer from '../business/businessSlice';
import hostViewReducer from '../host-view/slice';
import customMiddlewares from '../middlewares/middleware';
import modalFormReducer from '../modalFormSlice';
import modalReducer from '../modalSlice';
import availabilityReducer from '../reservation/availabilitySlice';
import toolbarReducer from '../toolbarSlice';
import apiSlice from './api';

const rootReducer = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	auth: authReducer,
	modals: modalReducer,
	modalForms: modalFormReducer,
	hostView: hostViewReducer,
	toolbar: toolbarReducer,
	business: businessReducer,
	availability: availabilityReducer,
	service: areasReducer,
	app: appSlice,
});

export const devMode = process.env.NODE_ENV === 'development';

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(apiSlice.middleware, customMiddlewares),
	devTools: devMode,
});

export default store;
