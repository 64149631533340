import { useState } from 'react';

import ConfirmationModal from '../../_metronic/partials/modals/ConfirmationModal';

const useConfirmationModal = (_onCancel) => {
	const cancel = _onCancel || (() => {});
	const [show, setShow] = useState(false);
	const [action, setAction] = useState(() => {});

	const handleConfirmation = (confirmed) => {
		setShow(false);
		confirmed && action();
		!confirmed && cancel();
	};

	const confirmationModal = <ConfirmationModal show={show} onConfirm={handleConfirmation} />;
	const confirmAction = (new_action) => {
		setShow(true);
		setAction(() => new_action);
	};

	return [confirmAction, confirmationModal];
};

export default useConfirmationModal;
