import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import { I18nContext } from 'src/_metronic/i18n/Metronici18n';
import { getSessionStorage } from 'src/common/storage';

import { KTSVG } from '../../../helpers';
import { HeaderStoreMenu, HeaderUser, HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';

const itemClass = 'ms-2 ms-lg-3';
const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
const btnIconClass = 'svg-icon-1';

export const { REACT_APP_API_X_LOCALE: X_LOCALE } = process.env;

const Navbar = ({ onToggleDrawer }) => {
	let langContext = useContext(I18nContext);

	const [currentLang, setCurrentLang] = useState(getSessionStorage('language') ?? X_LOCALE);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const languageOptions = {
		en: { label: 'EN', flagSrc: '/media/flags/united-states.svg' },
		el: { label: 'GR', flagSrc: '/media/flags/greece.svg' },
	};

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const changeLanguage = (lang) => {
		setCurrentLang(lang);
		sessionStorage.setItem('language', lang);
		langContext.changeLanguage(lang);
		setDropdownOpen(false);
	};

	useEffect(() => {
		sessionStorage.setItem('language', currentLang);
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	const { config } = useLayout();
	return (
		<div className="app-navbar flex-shrink-0">
			<div className={clsx('app-navbar-item d-flex', itemClass)}>
				<div data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" className={btnClass}>
					<KTSVG path="/media/icons/duotune/ecommerce/ecm004.svg" className={btnIconClass} />
				</div>
				<HeaderStoreMenu />
			</div>

			<div ref={dropdownRef} className={clsx('app-navbar-item d-none d-sm-flex position-relative', itemClass)}>
				<div className="d-flex" role="button" aria-expanded={dropdownOpen} onClick={toggleDropdown}>
					<img
						className="me-2 rounded-circle"
						src={languageOptions[currentLang].flagSrc}
						alt={`${languageOptions[currentLang].name} Flag`}
						aria-hidden="true"
						width="24"
						height="24"
					/>
					<div className="d-flex align-items-center fw-bold fs-6">{languageOptions[currentLang].label}</div>
				</div>

				{dropdownOpen && (
					<div className="dropdown-menu position-absolute dropdown-position border-0 mt-2 shadow show">
						{currentLang === 'en' && (
							<div className="dropdown-item d-flex" onClick={() => changeLanguage('el')}>
								<img
									className="me-1 rounded-circle"
									src="/media/flags/greece.svg"
									alt="Greek Flag"
									aria-hidden="true"
									width="24"
									height="24"
								/>
								<div className="d-flex align-items-center ms-2 fw-bold fs-6">{languageOptions.el.label}</div>
							</div>
						)}
						{currentLang === 'el' && (
							<div className="dropdown-item d-flex" onClick={() => changeLanguage('en')}>
								<img
									className="me-1 rounded-circle"
									src="/media/flags/united-states.svg"
									alt="US Flag"
									aria-hidden="true"
									width="24"
									height="24"
								/>
								<div className="d-flex align-items-center ms-2 fw-bold fs-6">{languageOptions.en.label}</div>
							</div>
						)}
					</div>
				)}
			</div>

			<div className={clsx('app-navbar-item d-none d-sm-flex', itemClass)}>
				<HeaderUser />
				<HeaderUserMenu />
			</div>

			{config.app?.header?.default?.menu?.display && (
				<div className="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
					<div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle" onClick={onToggleDrawer}>
						<KTSVG path="/media/icons/duotune/text/txt001.svg" className={btnIconClass} />
					</div>
				</div>
			)}
		</div>
	);
};

export { Navbar };
