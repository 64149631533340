import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useForgotPasswordMutation } from 'src/reducers/auth/api';
import { devMode } from 'src/reducers/services/store';

import { ForgotPasswordFormInputs, forgotPasswordSchema } from '../schema';

export const ForgotPassword = () => {
	const intl = useIntl();

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting, touchedFields },
	} = useForm<ForgotPasswordFormInputs>({
		mode: 'all',
		resolver: yupResolver(forgotPasswordSchema(intl)),
	});

	const [forgotPassword, { isSuccess, isLoading, isError }] = useForgotPasswordMutation();
	const onSubmit: SubmitHandler<ForgotPasswordFormInputs> = (formData) => {
		const inputs = { body: formData };
		forgotPassword(inputs);
	};

	useEffect(() => {
		if (isSuccess) {
			reset();
		}
	}, [isSuccess]);

	return (
		<>
			<div className="card card-custom overlay min-h-125px shadow-none">
				<div className="card-body d-flex flex-column p-10">
					<form
						id="kt_login_password_reset_form"
						noValidate
						onSubmit={handleSubmit(onSubmit)}
						className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
					>
						<div className="text-center mb-10">
							<h1 className="text-dark fw-bolder mb-3">
								<FormattedMessage id="AUTH.FORGOT.TITLE" />
							</h1>
							<div className="text-gray-500 fw-semibold fs-6">
								<FormattedMessage id="AUTH.FORGOT.DESC" />
							</div>
						</div>

						{isError && (
							<div className="mb-lg-15 alert alert-danger">
								<div className="alert-text font-weight-bold">
									<FormattedMessage id="AUTH.FORGOT.ERROR" />
								</div>
							</div>
						)}

						{isSuccess && (
							<div className="mb-10 bg-light p-8 rounded">
								<div className="text-success">
									<FormattedMessage id="AUTH.FORGOT.RESET.PASSWORD" />
								</div>
							</div>
						)}

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-gray-900 fs-6">
								<FormattedMessage id="AUTH.INPUT.EMAIL" />
							</label>
							<input
								type="username"
								{...register('username')}
								className={clsx(
									'form-control bg-transparent',
									{ 'is-invalid': touchedFields.username && errors.username },
									{ 'is-valid': touchedFields.username && !errors.username }
								)}
								autoComplete="off"
								placeholder="admin@demo.com"
							/>
							{errors.username && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.username.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="d-flex flex-wrap justify-content-center pb-lg-0">
							<button type="submit" id="kt_password_reset_submit" className="btn btn-primary me-4" disabled={isSubmitting || !isValid}>
								<span className="indicator-label">
									<FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
								</span>
								{isLoading && (
									<span className="indicator-progress">
										<FormattedMessage id="AUTH.LOGIN.LOADING" />
										<span className="spinner-border spinner-border-sm align-middle ms-2" />
									</span>
								)}
							</button>
							<Link to="/auth/login">
								<button
									type="button"
									id="kt_login_password_reset_form_cancel_button"
									className="generic-button fw-bold me-3 me-sm-5 fs-5"
								>
									<FormattedMessage id="GENERAL.CANCEL" />
								</button>
							</Link>
						</div>
					</form>
				</div>
			</div>
			{devMode && <DevTool control={control} />}
		</>
	);
};
