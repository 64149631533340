import { DevTool } from '@hookform/devtools';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { devMode } from 'src/reducers/services/store';

import { SelectControl, Switch } from '../input';

const FilterBody = ({ filters, onApply, onReset }) => {
	const filterFormRef = useRef();
	const { handleSubmit, reset, control, resetField, getValues } = useForm({
		shouldFocusError: false,
		shouldUseNativeValidation: false,
	});

	const onSubmit = (data) => {
		const filtersValues = Object.keys(data).reduce((filterValue, key) => {
			if (data[key] !== 'default') {
				filterValue[key] = data[key];
			}
			return filterValue;
		}, {});
		onApply(filtersValues);
	};

	const handleReset = () => {
		const currentValues = getValues();
		onReset(currentValues);
		reset();
	};

	const filterInputs = filters.map((f) => {
		let component;
		switch (f.type) {
			case 'switch':
				component = <Switch key={f.name} control={control} {...f} />;
				break;
			case 'select':
				component = <SelectControl filtersTitle={true} key={f.name} control={control} {...f} resetField={resetField} />;
				break;
			// case 'datepicker':
			// 	component = <Datepicker key={f.name} control={control} {...f} />;
			// 	break;
			default:
		}
		return component;
	});

	// useEffect(() => {
	// 	reset();
	// }, [filters]);

	return (
		<div className="container">
			<div className="row g-5">
				<form
					data-np-autofill-type="identity"
					data-np-checked="1"
					data-np-watching="1"
					ref={filterFormRef}
					className={'max-vh-75 overflow-auto'}
					onSubmit={handleSubmit(onSubmit)}>
					{filterInputs}

					<div className="col-12">
						<div className="d-flex justify-content-end py-5">
							<div className="d-flex pe-5">
								<button type="button" className="generic-button fw-bold" onClick={handleReset}>
									<FormattedMessage id="FILTERS.RESET" />
								</button>
							</div>
							<div className="d-flex">
								<button type="submit" className="btn btn-primary">
									<FormattedMessage id="FILTERS.APPLY" />
								</button>
							</div>
						</div>
					</div>
				</form>

				{devMode && <DevTool control={control} />}
			</div>
		</div>
	);
};

export default FilterBody;
