import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import jwtDecode from 'jwt-decode';

import { isEmptyObj } from '../../common/misc';
import { getLocalStorage, getSessionStorage, isExpiredJWT, setLocalStorage } from '../../common/storage';
import { logOut } from '../auth/slice';

export const {
	REACT_APP_API_URL: API_ROOT,
	REACT_APP_API_X_LOCALE: X_LOCALE,
	REACT_APP_VERSION: APP_VERSION,
	REACT_APP_API_VERSION: API_VERSION,
} = process.env;

const B2BBasePath = '/b2b';
const baseUrl = API_ROOT + B2BBasePath;

const defaultHeaders = (headers) => {
	X_LOCALE && headers.set('X-Locale', X_LOCALE);
	API_VERSION && headers.set('X-API-Version', API_VERSION);
	APP_VERSION && headers.set('X-APP-Version', APP_VERSION);

	return headers;
};

export const baseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: (headers, { getState, endpoint }) => {
		defaultHeaders(headers);

		const language = getSessionStorage('language');
		if (language) {
			headers.set('X-Locale', language);
		}

		if (endpoint !== 'getTranslations') {
			const accessToken = getLocalStorage('accessToken');
			if (accessToken) {
				headers.set('Authorization', `Bearer ${accessToken}`);
			}
		}

		const activeBusiness = getState().business.activeBusiness;
		if (activeBusiness) {
			headers.set('X-Business-Id', activeBusiness._id);
		}

		return headers;
	},
});

const refreshTokenBaseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: (headers) => {
		defaultHeaders(headers);

		const language = getSessionStorage('language');
		if (language) {
			headers.set('X-Locale', language);
		}
		const refreshToken = getLocalStorage('refreshToken');
		if (refreshToken) {
			headers.set('Authorization', `Bearer ${refreshToken}`);
		}

		return headers;
	},
});

let isRefreshing = false;

export const baseQueryWithReauth = async (args, api, extraOptions) => {
	const AUTH_PATH = '/auth/';
	const pathname = window.location?.pathname || '/';
	let result = await baseQuery(args, api, extraOptions);

	if (result?.error?.status === 401 && !pathname?.includes(AUTH_PATH)) {
		const refreshToken = getLocalStorage('refreshToken');
		const jwt = refreshToken ? jwtDecode(refreshToken) : {};

		if ((isEmptyObj(jwt) || isExpiredJWT(jwt)) && !pathname?.includes(AUTH_PATH)) {
			api.dispatch(logOut());
			return;
		}

		if (!isRefreshing) {
			isRefreshing = true;
			const refreshResult = await refreshTokenBaseQuery(
				{
					url: '/auth/refresh',
					method: 'POST',
				},
				api,
				extraOptions
			);

			if (refreshResult?.error && !pathname?.includes(AUTH_PATH)) {
				api.dispatch(logOut());
				return;
			}

			const { access_token: access, refresh_token: refresh } = refreshResult.data;

			setLocalStorage('user', JSON.stringify(refreshResult.data));
			setLocalStorage('accessToken', access);
			setLocalStorage('refreshToken', refresh);

			isRefreshing = false;
		}

		result = await baseQuery(args, api, extraOptions);
	}

	return result;
};

const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: 0,
	endpoints: (builder) => ({}),
	tagTypes: [
		'Areas',
		'AreaTables',
		'Blocking',
		'Business',
		'Reservation',
		'ReservationDetails',
		'ReservationPreferences',
		'Location',
		'Files',
		'Tags',
		'TagCategories',
		'assignedTags',
		'Contact',
		'ElenxisSettings',
		'Stores',
		'StoreTraits',
		'StoreDetails',
		'TranslationLanguages',
		'Events',
		'Tables',
		'Tickets',
		'Ticket-Types',
		'Feedback',
	],
});

export default apiSlice;
