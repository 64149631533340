import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { Header } from './Header';
import { Navbar } from './Navbar';

export function HeaderWrapper() {
	const { config, classes } = useLayout();

	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [isAnimating, setIsAnimating] = useState(true);

	const drawerRef = useRef<HTMLDivElement | null>(null);

	const toggleDrawer = () => {
		setDrawerOpen(!isDrawerOpen);
	};

	const handleOutsideClick = (event: MouseEvent) => {
		const target = event.target as HTMLElement;

		if (drawerRef.current && (drawerRef.current.contains(target) || target.closest('.modal-open'))) {
			return;
		}

		setDrawerOpen(false);
	};

	useEffect(() => {
		if (isDrawerOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [isDrawerOpen]);

	useEffect(() => {
		let resizeTimeout;

		const handleResize = () => {
			setIsAnimating(false);
			clearTimeout(resizeTimeout);
			resizeTimeout = setTimeout(() => {
				setIsAnimating(true);
			}, 200);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			clearTimeout(resizeTimeout);
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	if (!config.app?.header?.display) {
		return null;
	}

	return (
		<div id="kt_app_header" className="app-header flex-column justify-content-center">
			<div
				id="kt_app_header_container"
				className={clsx('app-container flex-lg-grow-1', classes.headerContainer.join(' '), config.app?.header?.default?.containerClass)}
			>
				{config.app.sidebar?.display && (
					<>
						<div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
							<div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
								<KTSVG path="/media/icons/duotune/abstract/abs015.svg" className=" svg-icon-1" />
							</div>
						</div>
						{/* <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0"> */}
						<div className="d-flex align-items-center">
							<Link to="/dashboard" className="d-lg-none">
								<img alt="Logo" src={toAbsoluteUrl('/media/logos/custom-1.png')} className="h-25px responsive-logo" />
							</Link>
						</div>
					</>
				)}

				{!config.app.sidebar?.display && (
					<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
						<Link to="/dashboard">
							{config.layoutType !== 'dark-header' ? (
								<img
									alt="Logo"
									src={toAbsoluteUrl('/media/logos/default-dark.svg')}
									className="h-20px h-lg-30px app-sidebar-logo-default"
								/>
							) : (
								<>
									<img
										alt="Logo"
										src={toAbsoluteUrl('/media/logos/default.svg')}
										className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
									/>
									<img
										alt="Logo"
										src={toAbsoluteUrl('/media/logos/default-dark.svg')}
										className="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
									/>
								</>
							)}
						</Link>
					</div>
				)}

				<div className="d-flex w-100 align-items-center justify-content-end justify-content-lg-between">
					{config.app.header.default?.content === 'menu' && config.app.header.default.menu?.display && (
						<div
							ref={drawerRef}
							className={`app-header-menu custom-drawer ${isDrawerOpen ? 'open' : ''} ${isAnimating ? '' : 'no-transition'}`}
							role="dialog"
							aria-labelledby="drawerLabel"
						>
							<Header />
						</div>
					)}
					<Navbar onToggleDrawer={toggleDrawer} />
				</div>
			</div>
			{isDrawerOpen && <div className="drawer-overlay"></div>}
		</div>
	);
}
