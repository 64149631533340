import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { KTSVG } from 'src/_metronic/helpers';
import { setShowModal } from 'src/reducers/modalSlice';

import { selectActiveBusiness, selectActiveStore, selectMemoBusinessStores, setActiveStore } from '../../../../reducers/business/businessSlice';

interface Store {
	_id: string;
	title: string;
}

const HeaderStoreMenu = () => {
	const activeStore = useSelector(selectActiveStore);
	const activeBusiness = useSelector(selectActiveBusiness);
	const businessStores = useSelector(selectMemoBusinessStores(activeBusiness?._id));
	const dispatch = useDispatch();

	const handleActiveStoreToggle = (store: Store): void => {
		dispatch(
			setActiveStore({
				_id: store?._id,
				title: store?.title,
			})
		);
	};

	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-4 fs-6 p-4 w-275px"
			data-kt-menu="true"
		>
			{businessStores.length > 0 &&
				activeStore &&
				businessStores.map((store: Store) => (
					<div
						key={store._id}
						className="d-flex justify-content-start align-items-center pb-3"
						onClick={() => handleActiveStoreToggle(store)}
					>
						<div className="form-check form-check-custom form-check-solid">
							<input
								className="form-check-input me-2 cursor-pointer"
								value={store._id}
								type="radio"
								id={store._id}
								name={store.title}
								checked={activeStore._id === store._id}
							/>
							<label className="form-check-label cursor-pointer" htmlFor={`store-${store._id}`}>
								{store?.title}
							</label>
						</div>
					</div>
				))}
			<div className="menu-item" onClick={() => dispatch(setShowModal({ type: 'location_new' }))}>
				<a className="menu-link justify-content-center">
					<KTSVG path="/media/icons/duotune/general/gen035.svg" className="svg-icon-primary svg-icon-1 me-3" />
					<FormattedMessage id="HEADER.MENU.ORGANIZATION.ADD" />
				</a>
			</div>
		</div>
	);
};

export { HeaderStoreMenu };
