import prepareURL from 'src/common/prepareURL';
import { ApiResponse, BlockingHoursItem, BlockingHoursResponse } from 'src/pages/blocking/schema';
import apiSlice from 'src/reducers/services/api';

const businessStoreBasePath = 'business/{bid}/store/{sid}';

const blockingHoursApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBlockingHours: builder.query<Array<BlockingHoursItem>, any>({
			query: (args) => prepareURL(businessStoreBasePath, args),
			transformResponse: (response: ApiResponse): BlockingHoursItem[] => {
				const blockings: BlockingHoursResponse[] = response?.item?.online_booking_settings?.blocking ?? [];

				return blockings?.map((blocking: BlockingHoursResponse, index: number) => ({
					id: index + 1,
					date: new Date(blocking.date),
					startTime: blocking.time[0],
					endTime: blocking.time[1],
					tabol: blocking.tabol,
					walk_in: blocking.walk_in,
					widget: blocking.widget,
				}));
			},
		}),
		updateBlockingHours: builder.mutation<BlockingHoursItem[], { body: any; params: { args: any } }>({
			query: (input) => {
				const {
					body,
					params: { args },
				} = input;

				return {
					url: prepareURL(`${businessStoreBasePath}/blocking-hours`, args),
					method: 'PUT',
					body,
				};
			},
			transformResponse: (response: any): BlockingHoursItem[] => {
				const blockings: BlockingHoursResponse[] = response?.store?.online_booking_settings?.blocking ?? [];

				return blockings.map((blocking, index) => ({
					id: index + 1,
					date: new Date(blocking.date),
					startTime: blocking.time[0],
					endTime: blocking.time[1],
					tabol: !!blocking.tabol,
					walk_in: !!blocking.walk_in,
					widget: !!blocking.widget,
				}));
			},
		}),
	}),
});

export const { useGetBlockingHoursQuery, useUpdateBlockingHoursMutation } = blockingHoursApiSlice;
