import { createSelector } from '@reduxjs/toolkit';
import { injectTags } from 'src/pages/analytics/tag/api/tagApiSlice';

import prepareURL from '../common/prepareURL';
import apiSlice, { baseQueryWithReauth } from './services/api';

const basePath = '/contacts';
const appPath = '/app';
const getVariationPath = (variation) => {
	switch (variation) {
		case 'customers':
			return basePath + '/customers';
		case 'leads':
			return basePath + '/leads';
		case 'contacts':
		default:
			return basePath;
	}
};

const prepareConactsURL = (args, resource = '') => {
	const path = getVariationPath(args.variation);
	return prepareURL(path + resource, args);
};

const contactApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		contacts: builder.query({
			query: (args) => prepareConactsURL(args),
			providesTags: ['Contacts'],
		}),
		contactsAndTags: builder.query({
			async queryFn(args, api, extraOptions) {
				//const tags = await fetchBaseQuery(getTags, args, extraOptions, loader)();

				let result = await baseQueryWithReauth({ url: prepareConactsURL(args) }, api, extraOptions);
				const contacts = result.data.contacts;
				try {
					const contactsWithTags = await injectTags({ items: contacts, api, extraOptions });
					result.data.contacts = contactsWithTags;
					return { ...result };
				} catch (error) {
					return { ...result };
				}
			},
			providesTags: [{ type: 'ContactTags' }, { type: 'Contacts' }],
		}),
		contact: builder.query({
			query: (args) => prepareConactsURL(args, '/{id}'),
			providesTags: ['Contact'],
		}),
		changeContactType: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}/type'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: [{ type: 'Contacts' }, { type: 'ContactTags' }, 'Contact'],
		}),
		changeContactDeactivation: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}/deactivation'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: [{ type: 'Contacts' }, { type: 'ContactTags' }, 'Contact'],
		}),
		createContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: [{ type: 'Contacts' }, { type: 'ContactTags' }, 'Contact'],
		}),
		updateContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: [{ type: 'Contacts' }, { type: 'ContactTags' }, 'Contact'],
		}),
		deleteContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}'),
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'Contacts' }, { type: 'ContactTags' }, 'Contact'],
			refetchOn: 'fulfilled',
		}),
		searchContacts: builder.query({
			query: (args) => prepareConactsURL(args, '/search'),
		}),

		sources: builder.query({
			query: (args) => prepareURL(`${basePath}/sources`, args),
			transformResponse: (response) => {
				return response?.map((source) => ({
					value: source?.key,
					label: source?.title,
				}));
			},
		}),
		departments: builder.query({
			query: (args) => prepareURL(`${basePath}/departments`, args),
			transformResponse: (response) => {
				return response?.map((department) => ({
					value: department?.key,
					label: department?.title,
				}));
			},
		}),
		industries: builder.query({
			query: (args) => prepareURL(`${basePath}/industries`, args),
			transformResponse: (response) => {
				return response?.map((department) => ({
					value: department?.key,
					label: department?.title,
				}));
			},
		}),
		roles: builder.query({
			query: (args) => prepareURL('/users/list', args),
			transformResponse: (response) => {
				return response?.users.map((role) => ({
					value: role?._id,
					label: role?.name,
				}));
			},
		}),
		status: builder.query({
			query: (args) => prepareURL(`${basePath}/lead-statuses`, args),
			transformResponse: (response) => {
				return response?.map((status) => ({
					value: status?.key,
					label: status?.title,
				}));
			},
		}),
		ratings: builder.query({
			query: (args) => prepareURL(`${basePath}/ratings`, args),
			transformResponse: (response) => {
				return response?.map((rating) => ({
					value: rating?.key,
					label: rating?.title,
				}));
			},
		}),
		timezones: builder.query({
			query: (args) => prepareURL(`${appPath}/all-timezones`, args),
			transformResponse: (response) => {
				return response?.timezones.map((timezone) => ({
					value: timezone?.name,
					label: timezone?.name,
				}));
			},
		}),
		countries: builder.query({
			query: (args) => prepareURL(`${appPath}/all-countries`, args),
			transformResponse: (response) => {
				return response?.countries.map((department) => ({
					value: department?.code,
					label: department?.title,
				}));
			},
		}),
		jobPositions: builder.query({
			query: (args) => prepareURL(`${basePath}/job-positions`, args),
			transformResponse: (response) => {
				return response.map((position) => ({
					value: position?.key,
					label: position?.title,
				}));
			},
		}),
		languages: builder.query({
			query: (args) => prepareURL(`${appPath}/all-languages`, args),
			transformResponse: (response) => {
				return response?.languages.map((lang) => ({
					value: lang?.code,
					label: lang?.title,
				}));
			},
		}),
		dial: builder.query({
			query: () => `${appPath}/dial-codes`,
			transformResponse: (response) => {
				return response['dial_codes'].map((lang) => ({
					value: lang?.key,
					label: lang?.key,
				}));
			},
		}),
	}),
});

const {
	useContactsQuery,
	useContactQuery,
	useLazyContactQuery,
	useChangeContactTypeMutation,
	useChangeContactDeactivationMutation,
	useCreateContactMutation,
	useUpdateContactMutation,
	useDeleteContactMutation,
	useContactsAndTagsQuery,
	useSearchContactsQuery,
	useSourcesQuery,
	useDepartmentsQuery,
	useRolesQuery,
	useStatusQuery,
	useRatingsQuery,
	useTimezonesQuery,
	useCountriesQuery,
	useJobPositionsQuery,
	useLanguagesQuery,
	useIndustriesQuery,
	useDialQuery,
} = contactApiSlice;

export {
	useChangeContactDeactivationMutation,
	useChangeContactTypeMutation,
	useContactQuery,
	useContactsAndTagsQuery,
	useContactsQuery,
	useCountriesQuery,
	useCreateContactMutation,
	useDeleteContactMutation,
	useDepartmentsQuery,
	useDialQuery,
	useIndustriesQuery,
	useJobPositionsQuery,
	useLanguagesQuery,
	useLazyContactQuery,
	useRatingsQuery,
	useRolesQuery,
	useSearchContactsQuery,
	useSourcesQuery,
	useStatusQuery,
	useTimezonesQuery,
	useUpdateContactMutation,
};
