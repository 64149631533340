import { isRejectedWithValue } from '@reduxjs/toolkit';
import { removeLocalStorage } from 'src/common/storage';
import { setApiError } from 'src/reducers/app/slice';
import { logOut } from 'src/reducers/auth/slice';

const localStorageMiddleware =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		if (typeof action === 'function') {
			return action(dispatch, getState);
		}

		// Skip non-object actions and pass them to the next middleware or reducer
		if (typeof action !== 'object' || action === null) {
			return next(action);
		}

		switch (action.type) {
			case logOut.type: {
				window.location.href = '/auth/login';

				removeLocalStorage('user');
				removeLocalStorage('accessToken');
				removeLocalStorage('refreshToken');
				break;
			}
			default:
				break;
		}

		return next(action);
	};

const rtkQueryErrorMiddleware =
	({ dispatch }) =>
	(next) =>
	(action) => {
		if (isRejectedWithValue(action)) {
			dispatch(setApiError(action.payload.originalStatus || action.payload.status));
		}
		return next(action);
	};

const middlewares = [localStorageMiddleware, rtkQueryErrorMiddleware];

export default middlewares;
