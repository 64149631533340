import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';

import Locales from './Locales';

type TextProps = {
	register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
	name: string;
	title?: string;
	placeholder?: string;
	errors?: DeepMap<FieldValues, FieldError>;
	textType?: string;
	disabled?: boolean;
	localized?: boolean;
	lang?: string;
	setLang?: (lang: string) => void;
	autoComplete?: string;
	responsiveTitle?: boolean;
	horizontalInput?: boolean;
	onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Text = ({
	register,
	name,
	title,
	placeholder = '',
	errors,
	textType = 'text',
	disabled = false,
	localized = false,
	lang,
	setLang,
	autoComplete,
	onInput,
	responsiveTitle = false,
	horizontalInput = false,
}: TextProps) => {
	return (
		<>
			{title && (
				<>
					<div className={clsx({ 'col-12 col-sm-3': horizontalInput })}>
						<label
							htmlFor={name}
							className={clsx('form-label pb-3 mb-0 fs-7 fw-bold', {
								'pt-5 pt-sm-0': responsiveTitle,
							})}
						>
							{title}
						</label>
						{localized && <Locales lang={lang!} setLang={setLang!} />}
					</div>
				</>
			)}

			<div className={clsx({ 'col-12 col-sm-9': horizontalInput })}>
				<input
					type={textType}
					className="form-control fs-6 fw-bold p-4"
					id={name}
					aria-describedby={name}
					placeholder={placeholder}
					disabled={disabled}
					autoComplete={autoComplete}
					style={disabled ? { background: '#e0e2e2' } : undefined}
					onInput={onInput}
					{...register(name)}
				/>
			</div>

			{errors && (
				<ErrorMessage
					errors={errors}
					name={name}
					render={({ message }) => (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{message}</span>
							</div>
						</div>
					)}
				/>
			)}
		</>
	);
};

export default Text;
