import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { customStyles } from 'src/common/inputStyles';

import { DIAL_CODES } from '../../common/constants';
import { useDialQuery } from '../../reducers/contactApiSlice';
import Select from './Select';

type Props = {
	watch?: UseFormWatch<any>;
	control: Control<any>;
	setValue?: UseFormSetValue<any>;
	name: string;
	title?: string;
	errors: DeepMap<FieldValues, FieldError>;
	disabled?: boolean;
	horizontalPhone?: boolean;
};

interface formatPhoneNumberProps {
	countryCode: string;
	remainingNumber: string;
}

const PhoneNumber: React.FC<Props> = ({ watch, control, setValue, name, title, errors, disabled = false, horizontalPhone = false }) => {
	// @ts-ignore TODO - Query to be moved to generic API slice (in TS format) and set the Return and Argument type in the builder query
	const { data: dialCodes, isLoading: isLoadingDial } = useDialQuery();
	const [number, setNumber] = useState('');
	const [code, setCountryCode] = useState('+30');
	const formatPhoneNumber = (phoneNumber: string): formatPhoneNumberProps => {
		if (!isLoadingDial) {
			const matchingCode = dialCodes.find((code: { value: string }) => phoneNumber?.startsWith(code.value))?.value;
			if (matchingCode) {
				const countryCode = matchingCode;
				const remainingNumber = phoneNumber.slice(matchingCode.length);
				setCountryCode(countryCode);
				setNumber(remainingNumber);
				return { countryCode, remainingNumber };
			}
		}
		return { countryCode: '+30', remainingNumber: '' };
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, onChange } }) => {
				return (
					<>
						<div className={clsx({ 'row align-items-center': horizontalPhone })}>
							<div className={clsx({ 'col-12 col-sm-3': horizontalPhone })}>
								<div className="form-label pb-3 mb-0 fw-bold fs-7">{title}</div>
							</div>
							<div className={clsx({ 'col-12 col-sm-9': horizontalPhone })}>
								<div className="input-group">
									<div className="row">
										<div className="col-4 col-sm-5">
											<Select
												value={{ value: code ?? '+30', label: code ?? '+30' }}
												onChange={(e) => {
													setCountryCode(e.value);
													formatPhoneNumber(`${e.value}${number}`);
													if (setValue) {
														setValue(name, `${e.value}${number}`);
													}
												}}
												options={dialCodes}
												defaultValue={{ value: code, label: code }}
												isDisabled={disabled}
												horizontalPhone={horizontalPhone}
												styles={customStyles}
											/>
										</div>

										<div className="col-8 col-sm-7">
											{/* <label className="form-label pb-3 mb-0 fw-bold">{title}</label> */}
											<input
												name="number"
												type="text"
												className="form-control fs-6 fw-bold p-4"
												placeholder={'Phone'}
												defaultValue={
													watch
														? formatPhoneNumber(watch(name))?.remainingNumber ?? ''
														: formatPhoneNumber(number)?.remainingNumber
												}
												onChange={(e) => {
													formatPhoneNumber(`${code}${e.target.value}`);
													if (setValue) {
														setValue(name, `${code}${e.target.value}`);
													}
												}}
												disabled={disabled}
											/>
										</div>
										{errors && (
											<ErrorMessage
												errors={errors}
												name={name}
												render={({ message }) => (
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{message}</span>
														</div>
													</div>
												)}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</>
				);
			}}
		/>
	);
};

export default PhoneNumber;
