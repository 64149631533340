import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Modal as RBModal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { areModalFormsActive } from 'src/reducers/modalFormSlice';
import { activeModals, areModalsActive } from 'src/reducers/modalSlice';

import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import useConfirmationModal from './useConfirmationModal';
import useModal from './useModal';

const Modal = () => {
	const { maxWidth, title, onClose, border, type } = useModal();
	const modalFormsActive = useSelector(areModalFormsActive);
	const modalsActive = useSelector(activeModals);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [isOnTop, setIsOnTop] = useState(true);
	useEffect(() => {
		if ((modalsActive ?? []).length > 1) {
			if (modalsActive[modalsActive.length - 1].type === type) {
				console.log('setting on top to true for ', type, modalsActive);
				setIsOnTop(true);
			} else {
				console.log('setting on top to false for ', type, modalsActive);
				setIsOnTop(false);
			}
		}
	}, [modalsActive]);
	useEffect(() => {
		if (confirmModalOpen) {
			confirmAction(() => onClose());
		}
	}, [confirmModalOpen]);

	const [confirmAction, confirmationModal] = useConfirmationModal(() => {
		setConfirmModalOpen(false);
	});

	return (
		<>
			{confirmationModal}

			<RBModal
				id={`kt_modal_${title}`}
				tabIndex={-1}
				onEscapeKeyDown={(e) => {
					e.preventDefault();
					setConfirmModalOpen(true);
				}}
				aria-hidden
				dialogClassName={clsx(`modal-dialog`, 'modal-dialog-centered', `${maxWidth}`, !isOnTop ? 'modal-invisible' : '')}
				style={{ zIndex: confirmModalOpen ? 1000 : 1100 }}
				show
				onHide={() => {
					setConfirmModalOpen(true);
				}}
				backdrop={modalsActive}
			>
				<ModalHeader />
				<ModalBody border />
				<ModalFooter />
			</RBModal>
		</>
	);
};

export default Modal;
