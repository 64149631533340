import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { KTSVG } from '../../_metronic/helpers';
import useModal from './useModal';

const ModalHeader = () => {
	const intl = useIntl();
	const [tagCoordinates, setTagCoordinates] = useState(null);
	const { editMode, title, onClose, tags } = useModal();
	const [tagLocation, setTagLocation] = useState(null);
	const tagIcon = useRef(null);

	const toggleDiv = (e) => {
		if (!tagCoordinates) {
			setTagCoordinates({ x: e.pageX, y: e.pageY });
			tagIcon.current &&
				setTagLocation({
					left: tagIcon.current.getBoundingClientRect().x - tagIcon.current.getBoundingClientRect().width / 2,
					top: tagIcon.current.getBoundingClientRect().y + tagIcon.current.getBoundingClientRect().height,
				});
		} else {
			setTagCoordinates(null);
			setTagLocation(null);
		}
	};

	return (
		<div className="modal-header">
			<div className="d-flex">
				<h1 className="text-capitalize">{editMode ? `${intl.formatMessage({ id: 'GENERAL.EDIT' })} ${title}` : title}</h1>
			</div>
			<div className="d-flex">
				{tags && (
					<div ref={(el) => (tagIcon.current = el)} className="btn btn-sm btn-icon btn-active-color-primary mx-4" onClick={toggleDiv}>
						<i className="fas fs-2 fa-tags" />
					</div>
				)}
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose}>
					<KTSVG className="svg-icon-1" path="/media/icons/duotune/abstract/abs012.svg" />
				</div>
			</div>
		</div>
	);
};

export default ModalHeader;
