export enum UserRoleEnum {
	SUADMIN = 'suadmin',
	OWNER = 'owner',
	ADMIN = 'admin',
	STORE_MANAGER = 'store_manager',
	HOST = 'host',
	ACCOUNTANT = 'accountant',
	EMPLOYEE = 'employee',
}

export enum FilteredUserRoleEnum {
	ADMIN = UserRoleEnum.ADMIN,
	STORE_MANAGER = UserRoleEnum.STORE_MANAGER,
	HOST = UserRoleEnum.HOST,
	ACCOUNTANT = UserRoleEnum.ACCOUNTANT,
	EMPLOYEE = UserRoleEnum.EMPLOYEE,
}

export interface IOwner {
	_id: string;
	name: string;
	role: UserRoleEnum;
}

export interface ILoginRequest {
	username: string;
	password: string;
	owner?: string;
}

export interface ILoginResponse {
	access_token: string;
	refresh_token: string;
	username: string;
	name: string;
	role: string;
}

export interface IAuthState {
	user: UserRoleEnum | null;
}
