export enum TabolRoles {
	ADMIN = 'ADMIN',
	USER = 'USER',
	CUSTOMER = 'CUSTOMER',
}

export interface AppState {
	apiError: string | null;
	role: TabolRoles | null;
	sidebarExpanded: boolean;
}

export interface Language {
	code: string;
	name: string;
	abbr: string;
	flag?: string;
}

export interface LanguagesResponse {
	languages: Language[];
}
