import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Table from 'src/components/table/Table';

import useConfirmationModal from '../../../../../components/modal/useConfirmationModal';

const OpeningHoursTable = ({ onEditHour, onDeleteHour }) => {
	const [confirmAction, confirmationModal] = useConfirmationModal();
	return (
		<div className="mb-5 mb-xl-8">
			<div className="py-2">
				<Table hasBorder={true}>
					<Table.Column field="day" sortable>
						<FormattedMessage id="LOCATION.OPENING_HOURS.TABLE.DAY" />
					</Table.Column>
					<Table.Column field="time">
						<FormattedMessage id="LOCATION.OPENING_HOURS.TABLE.TIME" />
					</Table.Column>

					<Table.Column
						sticky={true}
						field="action"
						body={(row) => (
							<Dropdown>
								<Dropdown.Toggle as="button" className="btn btn-sm" id="store_actions_dropdown">
									<i className="bi bi-three-dots-vertical fs-4" />
								</Dropdown.Toggle>

								<Dropdown.Menu style={{ position: 'absolute', zIndex: 1000 }}>
									<Dropdown.Item className="btn btn-sm text-primary" onClick={() => onEditHour(row._id)}>
										<FormattedMessage id="GENERAL.EDIT" />
									</Dropdown.Item>
									<Dropdown.Item className="btn btn-sm error-text" onClick={() => confirmAction(() => onDeleteHour(row._id))}>
										<FormattedMessage id="GENERAL.DELETE" />
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
					>
						<FormattedMessage id="GENERAL.ACTION" />
					</Table.Column>
				</Table>
				{confirmationModal}
			</div>
		</div>
	);
};

export default OpeningHoursTable;
