import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import BlockingModalBody from 'src/pages/blocking/BlockingModalBody';
import BusinessModalBody from 'src/pages/business/BusinessModalBody';
import StoreModalBody from 'src/pages/settings/stores/modal/StoreModalBody';
import { useDeleteBusinessMutation } from 'src/reducers/business/businessApiSlice';
import { useDeleteStoreMutation } from 'src/reducers/stores/storeApiSlice';

import Modal from '../../components/modal/Modal';
import { ModalContext } from '../../components/modal/useModal';
import ReservationModalBody from '../../pages/reservations/ReservationModalBody/ReservationModalBody';
import { selectActiveBusiness } from '../../reducers/business/businessSlice';
import { selectModal, setShowModal } from '../../reducers/modalSlice';
import { useDeleteReservationMutation } from '../../reducers/reservation/reservationApiSlice';
import { reInitMenu } from '../helpers';
import { Content } from './components/content';
import { HeaderWrapper } from './components/header';
import { ScrollTop } from './components/scroll-top';
import { Sidebar } from './components/sidebar';
import { ToolbarWrapper } from './components/toolbar/ToolbarWrapper';
import { PageDataProvider } from './core';

const MasterLayout = () => {
	const intl = useIntl();
	const location = useLocation();
	const dispatch = useDispatch();

	const storeFormRef = useRef();
	const blockingFormRef = useRef();
	const businessFormRef = useRef();
	const reservationFormRef = useRef();

	const [deleteStore] = useDeleteStoreMutation();
	const [deleteBusiness] = useDeleteBusinessMutation();
	const [deleteReservation] = useDeleteReservationMutation();

	const { show: blockingShowModal } = useSelector((state) => selectModal(state, 'blocking'));
	const { show: storeShowModal } = useSelector((state) => selectModal(state, 'location_new'));
	const { show: businessShowModal } = useSelector((state) => selectModal(state, 'business_new'));
	const { show: reservationShowModal, payload: reservationModalPayload } = useSelector((state) => selectModal(state, 'reservation_new'));

	const closeBusiness = () => dispatch(setShowModal({ type: 'business_new', show: false }));
	const closeLocation = () => dispatch(setShowModal({ type: 'location_new', show: false }));
	const closeBlockingHours = () => dispatch(setShowModal({ type: 'blocking', show: false }));
	const closeReservation = () => dispatch(setShowModal({ type: 'reservation_new', show: false }));

	const reservationContextValues = {
		ref: reservationFormRef,
		id: reservationModalPayload,
		title: reservationModalPayload
			? intl.formatMessage({ id: 'RESERVATIONS.LIST.TABLE_VALUES.TABLE_NAME' })
			: intl.formatMessage({ id: 'RESERVATIONS.LIST.ACTIONS.LABEL' }),
		body: <ReservationModalBody />,
		editMode: !!reservationModalPayload,
		onSubmitForm: () => {
			closeReservation();
			(reservationFormRef.current as any).onSubmit();
		},
		confirmedAction: (id: string) => {
			closeReservation();
			deleteReservation({ path: { id } });
		},
		onClose: () => closeReservation(),
	};

	const blockingContextValues = {
		ref: blockingFormRef,
		title: intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.HEADER' }),
		body: <BlockingModalBody />,
		onSubmitForm: () => {
			(blockingFormRef.current as any).onSubmit();
		},
		onClose: () => closeBlockingHours(),
	};

	const businessContextValues = {
		ref: businessFormRef,
		tags: true,
		title: intl.formatMessage({ id: 'MENU.NEW_BUSINESS' }),
		body: <BusinessModalBody />,
		onSubmitForm: () => {
			(businessFormRef.current as any).onSubmit();
		},
		onClose: () => closeBusiness(),
	};

	const { _id: business_id } = useSelector(selectActiveBusiness);
	const storeContextValues = {
		ref: storeFormRef,
		tags: true,
		title: intl.formatMessage({ id: 'LOCATION.MODAL.TITLE' }),
		body: <StoreModalBody />,
		onSubmitForm: () => {
			closeLocation();
			(storeFormRef.current as any).onSubmit();
		},
		onClose: () => closeLocation(),
	};

	useEffect(() => {
		reInitMenu();
	}, [location.key]);

	return (
		<PageDataProvider>
			<div className="d-flex flex-column flex-root app-root" id="kt_app_root">
				<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
					<HeaderWrapper />
					<div className="app-wrapper padding-top-fix flex-column flex-row-fluid" id="kt_app_wrapper">
						<Sidebar />
						<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
							<div className="d-flex flex-column flex-column-fluid">
								<ToolbarWrapper />
								<Content>
									<Outlet />
								</Content>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ModalContext.Provider value={storeContextValues}>{storeShowModal && <Modal />}</ModalContext.Provider>
			<ModalContext.Provider value={blockingContextValues}>{blockingShowModal && <Modal />}</ModalContext.Provider>
			<ModalContext.Provider value={businessContextValues}>{businessShowModal && <Modal />}</ModalContext.Provider>
			<ModalContext.Provider value={reservationContextValues}>{reservationShowModal && <Modal />}</ModalContext.Provider>

			<ScrollTop />
		</PageDataProvider>
	);
};

export default MasterLayout;
