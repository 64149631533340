import clsx from 'clsx';
import React, { useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

type Button = {
	value: string;
	label: string;
};

type Props = {
	register: UseFormRegister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	setValue?: UseFormSetValue<FieldValues>;
	title?: string;
	name: string;
	buttons: Array<Button>;
	onRadioChange: (value: string) => void;
	disabled?: boolean;
};

const Radio: React.FC<Props> = ({ register, watch, setValue, title, name, buttons, onRadioChange, disabled = false }) => {
	const [isChecked, setChecked] = React.useState('');
	const radioChecked = watch(name);
	useEffect(() => {
		if (radioChecked) {
			setChecked(radioChecked);
			onRadioChange(radioChecked);
			if (setValue) {
				setValue(name, radioChecked);
				onRadioChange(radioChecked);
			}
		} else setChecked(buttons[0].value);
	}, [radioChecked, isChecked, onRadioChange]);

	return (
		<div className="col-12">
			<div className="pb-3 fs-7 fw-bold pt-5">{title}</div>
			<div className="d-flex">
				{buttons.map(({ value, label }, idx) => (
					<div key={value} className={clsx('form-check form-check-custom form-check-solid cursor-pointer', { 'ps-5': idx > 0 })}>
						<input
							className="form-check-input"
							{...register(name)}
							type="radio"
							value={value}
							id={value}
							disabled={disabled}
							checked={isChecked === value}
							defaultValue={watch(name) ?? buttons[0].value}
							onClick={() => onRadioChange(value)}
						/>
						<label className="ms-2 fw-bold cursor-pointer" htmlFor={value}>
							{label}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default Radio;
