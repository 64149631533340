import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CheckBox, Datepicker, SelectControl } from 'src/components/input';
import useModal from 'src/components/modal/useModal';
import Divider from 'src/components/utils/Divider';
import Label from 'src/components/utils/Label';
import { DateFormat, formatDate } from 'src/helpers/dates';
import { useGetBlockingHoursQuery, useUpdateBlockingHoursMutation } from 'src/reducers/blocking-hours/api';
import { selectActiveBusiness, selectActiveStore } from 'src/reducers/business/businessSlice';
import { devMode } from 'src/reducers/services/store';

import BlockingItem from './BlockingItem';
import { BlockingHoursItem, BlockingHoursResponse, initialValues, validationSchema } from './schema';
import { generateBlockingHours } from './utils';

const BlockingModalBody = () => {
	const intl = useIntl();
	const { ref } = useModal();

	const { _id: sid } = useSelector(selectActiveStore);
	const { _id: bid } = useSelector(selectActiveBusiness);
	const params = { args: { path: { bid, sid } } };

	const { isSuccess, data: blockings } = useGetBlockingHoursQuery({ path: { bid, sid } });
	const [updateBlockingHours, { isSuccess: updateBlockingSuccesfully, data: updatedBlockings }] = useUpdateBlockingHoursMutation();

	const [data, setData] = useState<BlockingHoursItem[]>([]);
	const [areaError, setAreaError] = useState<boolean>(false);
	const [editedItem, setEditedItem] = useState<string | number | null>(null);

	const nextDate = new Date();
	nextDate.setDate(nextDate.getDate() + 1);

	const formatBlockingHours = (data: BlockingHoursItem[]): BlockingHoursResponse[] => {
		return (
			data?.map((item) => ({
				date: formatDate(item.date, DateFormat.DAY_MONTH_YEAR_HYPHEN),
				time: [item.startTime, item.endTime],
				tabol: !!item.tabol,
				walk_in: !!item.walk_in,
				widget: !!item.widget,
			})) ?? []
		);
	};

	const {
		watch,
		reset,
		control,
		register,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<FieldValues>({
		mode: 'onSubmit',
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema),
	});

	const selectedDate = watch('date');
	const selectedTabol = watch('tabol');
	const selectedWidget = watch('widget');
	const selectedWalkIn = watch('walk_in');
	const selectedStartTime = watch('startTime');

	const handleEdit = (id: string | number) => {
		const selectedItem = data.find((item) => item.id === id);
		if (selectedItem) {
			setEditedItem(id);
			reset(selectedItem);
		}
	};

	const handleSave = (id: string | number) => {
		const updatedItem: BlockingHoursItem = {
			id,
			date: getValues('date'),
			startTime: getValues('startTime'),
			endTime: getValues('endTime'),
			tabol: getValues('tabol'),
			walk_in: getValues('walk_in'),
			widget: getValues('widget'),
		};

		const updatedData = data.map((item) => {
			if (item.id === id) {
				return updatedItem;
			}
			return item;
		});

		const formattedData = formatBlockingHours(updatedData);
		const body = {
			blocking_hours: formattedData,
		};

		const inputs = { body, params };
		updateBlockingHours(inputs);
		setEditedItem(null);
		reset({ ...initialValues, date: selectedDate });
	};

	const handleDelete = (id: string | number) => {
		const filteredData = data?.filter((item) => item.id !== id);
		const formattedData = formatBlockingHours(filteredData);

		const body = {
			blocking_hours: formattedData,
		};

		const inputs = { body, params };
		updateBlockingHours(inputs);
	};

	useImperativeHandle(ref, () => ({
		onSubmit: () => handleSubmit(handleOnSubmit)(),
	}));

	const handleOnSubmit = (inputs) => {
		const { date, startTime, endTime, tabol, walk_in, widget } = inputs;

		if (!tabol && !walk_in && !widget) {
			setAreaError(true);
			return;
		}

		const formattedData = formatBlockingHours(data);

		const body = {
			blocking_hours: [
				...formattedData,
				{
					date,
					time: [startTime, endTime],
					tabol,
					walk_in,
					widget,
				},
			],
		};

		updateBlockingHours({ body, params });
		reset({ ...initialValues, date: selectedDate });
	};

	const datePickerRange = (date: Date) => {
		const currentDate = new Date();
		const oneMonthAhead = new Date(currentDate);
		oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 1);

		return (
			formatDate(date, DateFormat.DAY_MONTH_YEAR_HYPHEN) >= formatDate(currentDate, DateFormat.DAY_MONTH_YEAR_HYPHEN) &&
			date.getTime() <= oneMonthAhead.getTime()
		);
	};

	const currentBlocked = useMemo(() => {
		return data.filter(
			(item) => formatDate(item.date, DateFormat.DAY_MONTH_YEAR_HYPHEN) === formatDate(selectedDate, DateFormat.DAY_MONTH_YEAR_HYPHEN)
		);
	}, [data, selectedDate]);

	useEffect(() => {
		if (areaError && (selectedTabol || selectedWalkIn || selectedWidget)) {
			setAreaError(false);
		}
	}, [selectedTabol, selectedWalkIn, selectedWidget, setAreaError]);

	useEffect(() => {
		if (isSuccess) {
			setData(blockings);
		}
	}, [isSuccess, blockings]);

	useEffect(() => {
		if (updateBlockingSuccesfully) {
			setData(updatedBlockings ?? []);
		}
	}, [updateBlockingSuccesfully, updatedBlockings]);

	return (
		<div className="modal-content">
			<form ref={ref}>
				<Label title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.DATE' })} />

				<div className="row">
					<div className="col-12">
						<Datepicker
							control={control}
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.DATE' })}
							name="date"
							startDate={new Date()}
							filterDate={datePickerRange}
							errors={errors}
							required
						/>
					</div>
				</div>

				<Divider />

				{currentBlocked.length > 0 && (
					<>
						<Label title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.CURRENTLY_BLOCKED' })} />

						<div className="row">
							<div>
								{currentBlocked.map((blockedItem) => (
									<BlockingItem
										key={blockedItem.id}
										id={blockedItem.id}
										date={blockedItem.date}
										startTime={blockedItem.startTime}
										endTime={blockedItem.endTime}
										tabol={blockedItem.tabol}
										walk_in={blockedItem.walk_in}
										widget={blockedItem.widget}
										edited={blockedItem.id === editedItem}
										onEdit={handleEdit}
										onSave={handleSave}
										onDelete={handleDelete}
									/>
								))}
							</div>
						</div>

						<Divider />
					</>
				)}

				<Label
					title={
						editedItem
							? intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.EDIT' })
							: intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.BLOCK' })
					}
				/>

				<div className="row pb-5">
					<div className="col-12 col-sm-6">
						<SelectControl
							control={control}
							name="startTime"
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.START_BLOCK' })}
							options={generateBlockingHours() ?? []}
							errors={errors}
							required
						/>
					</div>
					<div className="col-12 col-sm-6">
						<SelectControl
							control={control}
							name="endTime"
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.END_BLOCK' })}
							isDisabled={!selectedStartTime}
							options={(generateBlockingHours() ?? []).filter((option) => option.value > selectedStartTime)}
							errors={errors}
							required
							responsiveTitle={true}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 d-flex flex-row">
						<CheckBox
							name="tabol"
							register={register}
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.TABOL' })}
							labelFirst={false}
							isSwitch={false}
						/>
						<CheckBox
							name="walk_in"
							register={register}
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.IN_STORE' })}
							labelFirst={false}
							isSwitch={false}
						/>
						<CheckBox
							name="widget"
							register={register}
							title={intl.formatMessage({ id: 'BLOCKING_HOURS.MODAL.BODY.WIDGET' })}
							labelFirst={false}
							isSwitch={false}
						/>
					</div>
					{areaError && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">
									<FormattedMessage id="BLOCKING_HOURS.MODAL.BODY.ALERT" />
								</span>
							</div>
						</div>
					)}
				</div>
			</form>

			{devMode && <DevTool control={control} />}
		</div>
	);
};

export default BlockingModalBody;
