import { passwordRegex } from 'src/types';
import * as Yup from 'yup';

export type LoginFormInputs = {
	username: string;
	password: string;
	owner: string;
};

export const loginSchema = (isSuAdmin: boolean, intl) =>
	Yup.object({
		username: Yup.string().required(intl.formatMessage({ id: 'ERROR_MESSAGE.USERNAME' })),
		password: Yup.string()
			.min(3, intl.formatMessage({ id: 'ERROR_MESSAGE.MINIMUM_SYMBOLS' }))
			.max(50, intl.formatMessage({ id: 'ERROR_MESSAGE.MAXIMUM_SYMBOLS' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.PASSWORD' })),
		owner: isSuAdmin ? Yup.string().required(intl.formatMessage({ id: 'ERROR_MESSAGE.OWNER_REQUIRED' })) : Yup.string().notRequired(),
	}).required();

export type ForgotPasswordFormInputs = {
	username: string;
};

export const forgotPasswordSchema = (intl) =>
	Yup.object({
		username: Yup.string()
			.email(intl.formatMessage({ id: 'ERROR_MESSAGE.EMAIL_FORMAT' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.EMAIL_FORMAT' })),
	}).required();

export type RegistrationFormInputs = {
	fname: string;
	lname: string;
	username: string;
	business_title: string;
	password: string;
	confirm_password: string;
	accept_terms: boolean;
};

export const registrationSchema = (intl) =>
	Yup.object({
		fname: Yup.string()
			.min(3, intl.formatMessage({ id: 'ERROR_MESSAGE.MINIMUM_SYMBOLS' }))
			.max(50, intl.formatMessage({ id: 'ERROR_MESSAGE.MAXIMUM_SYMBOLS' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.FIRST_NAME' })),
		lname: Yup.string()
			.min(3, intl.formatMessage({ id: 'ERROR_MESSAGE.MINIMUM_SYMBOLS' }))
			.max(50, intl.formatMessage({ id: 'ERROR_MESSAGE.MAXIMUM_SYMBOLS' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.LAST_NAME' })),
		username: Yup.string()
			.email('Invalid email format')
			.min(3, intl.formatMessage({ id: 'ERROR_MESSAGE.MINIMUM_SYMBOLS' }))
			.max(50, intl.formatMessage({ id: 'ERROR_MESSAGE.MAXIMUM_SYMBOLS' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.EMAIL' })),
		business_title: Yup.string()
			.min(3, intl.formatMessage({ id: 'ERROR_MESSAGE.MINIMUM_SYMBOL' }))
			.max(50, intl.formatMessage({ id: 'ERROR_MESSAGE.MAXIMUM_SYMBOLS' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.BUSINESS_NAME' })),
		password: Yup.string()
			.matches(passwordRegex, intl.formatMessage({ id: 'ERROR_MESSAGE.INVALID_PASSWORD' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.PASSWORD' })),
		confirm_password: Yup.string()
			.oneOf([Yup.ref('password')], intl.formatMessage({ id: 'ERROR_MESSAGE.PASSWORDS_MATCH' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.PASSWORD_CONFIRMATION' })),
		accept_terms: Yup.bool()
			.oneOf([true], intl.formatMessage({ id: 'ERROR_MESSAGE.TERMS' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.TERMS' })),
	}).required();

export type ResetPasswordFormInputs = Pick<RegistrationFormInputs, 'password' | 'confirm_password'>;

export const resetPasswordSchema = (intl) =>
	Yup.object({
		password: Yup.string()
			.matches(passwordRegex, intl.formatMessage({ id: 'ERROR_MESSAGE.INVALID_PASSWORD' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.PASSWORD' })),
		confirm_password: Yup.string()
			.oneOf([Yup.ref('password')], intl.formatMessage({ id: 'ERROR_MESSAGE.PASSWORDS_MATCH' }))
			.required(intl.formatMessage({ id: 'ERROR_MESSAGE.PASSWORD_CONFIRMATION' })),
	}).required();
