import prepareURL from 'src/common/prepareURL';
import apiSlice, { baseQueryWithReauth } from 'src/reducers/services/api';

const basePaths = '/app/tags';

const tagApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createTagCategory: builder.mutation({
			query: (category: CategoryShape) => {
				return { url: `${basePaths}/categories`, method: 'POST', body: category };
			},
			invalidatesTags: ['TagCategories'],
			transformResponse: (response: any) => response,
		}),
		getTagCategories: builder.query({
			query: (args) => prepareURL(`${basePaths}/categories`, args),
			providesTags: ['TagCategories'],
		}),
		getTagsForCategory: builder.query({
			query: (id: CategoryId) => `${basePaths}/categories/${id}`,
		}),
		deleteCategory: builder.mutation({
			query: (id: CategoryId) => ({
				url: `${basePaths}/categories/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['TagCategories'],
		}),
		createTag: builder.mutation({
			query: (tag: TagShape) => ({
				url: `${basePaths}`,
				method: 'POST',
				body: tag,
			}),
			invalidatesTags: ['Tags'],
			transformResponse: (response: CreateTagResponse) => response,
		}),
		editTag: builder.mutation({
			query: (tag: TagShape) => ({
				url: `${basePaths}/${tag._id}`,
				method: 'PUT',
				body: tag,
			}),
			invalidatesTags: ['Tags'],
			transformResponse: (response: CreateTagResponse) => response,
		}),
		editTagCategory: builder.mutation({
			query: ({ id, category }) => ({
				url: `${basePaths}/categories/${id}`,
				method: 'PUT',
				body: category,
			}),
			invalidatesTags: ['Tags', 'TagCategories'],
			transformResponse: (response: CreateTagResponse) => response,
		}),
		getTags: builder.query({
			query: (args) => prepareURL(`${basePaths}`, args),
			forceRefetch: () => true,
			// providesTags: ['Tags'],
			transformResponse: (response: GetTagResponse) => {
				let result = response.result
					//TODO ADD VALIDATION ON BE
					.filter((c) => !!c.category)
					.map((tag: TagDTO) => {
						return {
							id: tag._id,
							title: tag.title,
							icon: {
								color: tag.category.color,
								shape: tag.icon,
							},
							priority: tag.priority,
							category: tag.category,
							sections: tag.sections,
							records: tag.assigned_tags_count,
						};
					});
				return { count: response.count, result: result };
			},
			providesTags: ['Tags'],
		}),
		getTagsBySection: builder.query({
			query: (sections: string[]) => {
				return `${prepareURL(basePaths, { query: { sections } })}`;
			},
			forceRefetch: () => true,
			// providesTags: ['Tags'],
			transformResponse: (response: GetTagResponse) => {
				let result = response.result
					//TODO ADD VALIDATION ON BE
					.filter((c) => !!c.category)
					.map((tag: TagDTO) => {
						return {
							id: tag._id,
							title: tag.title,
							icon: {
								color: tag.category.color,
								shape: tag.icon,
							},
							priority: tag.priority,
							category: tag.category,
							sections: tag.sections,
							records: tag.assigned_tags_count,
						};
					});
				return { count: response.count, result: result };
			},
		}),
		deleteTags: builder.mutation({
			query: (id: string) => {
				return { url: `${basePaths}/${id}`, method: 'DELETE' };
			},
			invalidatesTags: ['Tags', 'TagCategories'],
		}),
		assignTag: builder.mutation({
			query: ({ tagId, reference }) => ({
				url: `${basePaths}/${tagId}/assign`,
				method: 'POST',
				body: {
					references: reference,
				},
			}),

			invalidatesTags: (result, error, args): any => {
				let invalidations: { type: string; id?: string }[] = [];
				for (const ref of args.reference) {
					if (ref.model == 'user') {
						invalidations.push({ type: 'Contact', id: 'PARTIAL-LIST' });
						invalidations.push({ type: 'ContactTags' });
					}
				}
				return [{ type: 'assignedTags' }, ...invalidations];
			},
		}),
		unassignTag: builder.mutation({
			query: ({ tagId, reference }) => ({
				url: `${basePaths}/${tagId}/unassign`,
				method: 'POST',
				body: {
					references: reference,
				},
			}),
			invalidatesTags: (result, error, args): any => {
				let invalidations: { type: string; id?: string }[] = [];
				for (const ref of args.reference) {
					if (ref.model == 'user') {
						invalidations.push({ type: 'Contact', id: 'PARTIAL-LIST' });
						invalidations.push({ type: 'ContactTags' });
					}
				}
				return [{ type: 'assignedTags' }, ...invalidations];
			},
		}),
		getAssignedTags: builder.query({
			query: (tagId: string) => ({
				url: `${basePaths}/${tagId}/assigned`,
			}),
			providesTags: ['assignedTags'],
		}),
		getTagsByid: builder.query({
			query: (id: string[]) => {
				return `${prepareURL(basePaths, { query: { id } })}`;
			},
			transformResponse: (response: GetTagResponse) => {
				let result = response.result;
				return result;
			},
		}),
		getTagsForRef: builder.query({
			query: (ids: string[]) => {
				return `${prepareURL(basePaths + '/assigned', { query: { ids } })}`;
			},
			transformResponse: (response: GetTagResponse) => {
				let result = response.result;
				return result;
			},
			providesTags: ['assignedTags'],
		}),
	}),
});

type TagIcon = {
	color: string;
	shape: string;
};

type TagReferenceShape = {
	id: string;
	model: TagReferenceModel;
};

type TagReferenceModel = 'user' | 'store' | 'area' | 'table' | 'contact' | 'reservation' | 'event';

export const createTagRef = (id: string, model: TagReferenceModel): TagReferenceShape => {
	return {
		id: id,
		model,
	};
};

export const createTagRefFromArray = (id: string[], model: TagReferenceModel): TagReferenceShape[] => {
	return id.map((i) => createTagRef(i, model));
};

type TagShape = {
	_id?: string;
	title: string;
	icon: string;
	priority: number;
	category_id: string;
	sections: string[];
};

type CategoryShape = {
	title: string;
	description: string;
	color: string;
};

type TagReference = {
	_id: string;
	title: string;
};

type AppSection = {
	_id: string;
	key?: string;
	title: string;
};

type RecInfo = {
	deleted: boolean;
	cr_stamp: string;
	mod_stamp: string;
	cr_uid: string;
	mod_uid: string;
	owner_id: string;
};

type CreateTagResponse = {
	success: boolean;
	tag: {
		title: string;
		icon: string;
		priority: number;
		sections: AppSection[];
		category: CategoryDTO;
		business: {
			_id: string;
			title: string;
		};
		_rec_info: RecInfo;
		_id: string;
		__v: number;
	};
};
type CategoryDTO = {
	_id: string;
	title: string;
	color: string;
};

type TagDTO = {
	_id: string;
	title: string;
	icon: string;
	priority: number;
	sections: AppSection[];
	category: CategoryDTO;
	business: {
		_id: string;
		title: string;
	};
	assigned_tags_count: number;
};

type GetTagResponse = {
	count: number;
	result: TagDTO[];
};

type CategoryId = string;
export type {
	AppSection,
	CategoryDTO,
	CategoryId,
	CategoryShape,
	CreateTagResponse,
	RecInfo,
	TagIcon,
	TagReference,
	TagReferenceModel,
	TagReferenceShape,
	TagShape,
};
export const {
	useCreateTagCategoryMutation,
	useGetTagCategoriesQuery,
	useDeleteCategoryMutation,
	useGetTagsQuery,
	useCreateTagMutation,
	useDeleteTagsMutation,
	useEditTagMutation,
	useEditTagCategoryMutation,
	useAssignTagMutation,
	useGetTagsForCategoryQuery,
	useLazyGetTagsForCategoryQuery,
	useGetTagsBySectionQuery,
	useGetAssignedTagsQuery,
	useLazyGetAssignedTagsQuery,
	useLazyGetTagsByidQuery,
	useGetTagsForRefQuery,
	useUnassignTagMutation,
} = tagApiSlice;
export default tagApiSlice;

export const injectTags = async ({ items, api, extraOptions }) => {
	console.time('injectTags');
	if (!items || items.length === 0) {
		console.timeEnd('injectTags');
		return items;
	}
	let ids = items.map((item) => item._id).sort((lhs, rhs) => lhs.localeCompare(rhs));
	try {
		let tagsForItems: any = await baseQueryWithReauth(
			{
				url: prepareURL('/app/tags' + '/assigned', { query: { ids } }),
			},
			api,
			extraOptions
		);
		if (!tagsForItems?.data?.result) {
			console.timeEnd('injectTags');
			return items;
		}
		if (tagsForItems?.data?.result.length === 0) {
			console.timeEnd('injectTags');
			return items;
		}
		if (tagsForItems?.isScuccess === false) {
			console.timeEnd('injectTags');
			return items;
		}
		let itemsDictionary = toDictionary(items);
		let tagResponse = tagsForItems?.data?.result;
		tagResponse.sort((lhs, rhs) => lhs.ref_id.localeCompare(rhs.ref_id));
		tagResponse.forEach((tag) => {
			let item = itemsDictionary[tag.ref_id];
			if (!!item && !item.tags) {
				item.tags = [];
				item.tags.push(tag.tag);
			} else {
				item.tags.push(tag.tag);
			}
		});
		console.log('items', items);
		console.timeEnd('injectTags');
		return items;
	} catch (error) {
		console.timeEnd('injectTags');
		return items;
	}
};

/**
 * Injects tags into items based on their IDs.
 * @param {Object} options - The options for injecting tags.
 * @param {Array} options.items - The items to inject tags into.
 * @param {Object} options.api - The rtl query api object for making requests.
 * @param {Object} options.extraOptions - Extra options for the API request.
 * @returns {Promise<Array>} The items with injected tags.
 */
const injectTagsOpt = async ({ items, api, extraOptions }) => {
	console.time('injectTags');
	let ids = items.map((item) => item._id).sort((lhs, rhs) => lhs.localeCompare(rhs));
	let tagsForItems: any = await baseQueryWithReauth(
		{
			url: prepareURL('/app/tags' + '/assigned', { query: { ids } }),
		},
		api,
		extraOptions
	);
	if (tagsForItems?.isScuccess === false) {
		return items;
	}
	if (!tagsForItems?.data?.result) {
		return items;
	}

	let tagResponse = tagsForItems?.data?.result;
	tagResponse.sort((lhs, rhs) => lhs.ref_id.localeCompare(rhs.ref_id));
	tagResponse.forEach((tag) => {
		let item = items.find((_item) => _item._id === tag.ref_id);
		if (!item.tags) {
			item.tags = [];
			item.tags.push(tag.tag);
		} else {
			item.tags.push(tag.tag);
		}
	});
	// console.log('items', items);
	console.timeEnd('injectTags');
	return items;
};

function toDictionary(arr) {
	let dict = {};
	for (let obj of arr) {
		if (obj._id) {
			dict[obj._id] = obj;
		}
	}
	return dict;
}
