import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { KTSVG } from '../../_metronic/helpers';
import { useUploadImageMutation } from '../../reducers/fileApiSlice';

type Props = {
	onUpload?: (media: any) => void;
	disabled?: boolean;
};

type MediaItem = {
	_id: string;
	title: string;
	url_sm: string;
	onChange?: (media: MediaItem) => void;
};

const File: React.FC<Props> = ({ onUpload, disabled = false }) => {
	const [uploadImage] = useUploadImageMutation();
	const [media, setMedia] = useState<MediaItem[]>([]);
	const [isUploading, setIsUploading] = useState(false);

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileInput = e.target;
		const file = fileInput.files?.[0];

		if (file && !isUploading) {
			try {
				setIsUploading(true);
				const formData = new FormData();
				formData.append('image', file);

				const response = await uploadImage(formData);
				if ('data' in response) {
					const newMedia: MediaItem = {
						_id: response.data.media._id,
						title: response.data.media.title,
						url_sm: response.data.media.url_sm,
					};
					onUpload && onUpload(response.data.media);
					setMedia((prevMedia) => [...prevMedia, newMedia]);
				}
			} catch (error) {
				console.error('File upload failed:', error);
			} finally {
				setIsUploading(false);
				// Reset the input value to ensure onChange triggers for the same file
				fileInput.value = '';
			}
		}
	};

	const id = useMemo(() => 'file' + new Date().getTime(), []);

	return (
		<div className="uploader-container fs-5 fw-bold">
			<div className="uploader-wrapper p-4">
				<input
					type="file"
					id={id}
					className="custom-checkbox w-100 h-100"
					accept="image/jpg, image/jpeg"
					onChange={handleChange}
					disabled={disabled || isUploading}
				/>
				<label htmlFor={id} className="uploader">
					<KTSVG className="svg-icon-4x svg-icon-primary" path="/media/icons/duotune/files/fil009.svg" />
					<div className="uploader-text">
						<span className="fs-bold">
							<FormattedMessage id="FILES.UPLOAD" />
						</span>
						<span className="fs-7 text-primary">
							<FormattedMessage id="FILES.DRAG.DROP" />
						</span>
					</div>
				</label>
			</div>
			{/* {media.length > 0 && (
				<div className="mt-3">
					{media.map((m) => (
						<div key={m._id} className="d-inline-flex align-items-center me-3 mb-2">
							<img
								src={'https://test-api.tabol.io/' + m.url_sm}
								alt={m.title}
								className="me-2"
								onClick={() => {
									setMedia(media.filter(({ _id }) => _id !== m._id));
								}}
								style={{ width: '40px', height: '40px', objectFit: 'cover' }}
							/>
							<span className="fs-7 text-primary">{m.title}</span>
						</div>
					))}
				</div>
			)} */}
		</div>
	);
};

export default File;
