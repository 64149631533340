function isSubsequence(a: Array<String>, b: Array<String>) {
	for (let i = 0; i <= a.length - b.length; i++) {
		let match = true;
		for (let j = 0; j < b.length; j++) {
			if (a[i + j] !== b[j]) {
				match = false;
				break;
			}
		}
		if (match) return true;
	}
	return false;
}

export function getCurrentUrl(pathname: string) {
	return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
	const current = getCurrentUrl(pathname);
	if (!current || !url) {
		return false;
	}

	if (current === url) {
		return true;
	}

	const a = pathname.split('/');
	const b = url.split('/');

	if (isSubsequence(a, b)) {
		return true;
	}

	return false;
}
