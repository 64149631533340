import { createSlice } from '@reduxjs/toolkit';

import { AppState, TabolRoles } from './types';

const initialAppState: AppState = {
	apiError: null,
	role: TabolRoles.USER,
	sidebarExpanded: true,
};

const appSlice = createSlice({
	name: 'app',
	initialState: initialAppState,
	reducers: {
		setApiError: (state, action) => {
			state.apiError = action.payload;
		},
		clearApiError: (state) => {
			state.apiError = null;
		},
		setRole: (state, action) => {
			state.role = action.payload;
		},
		setSidebarExpanded: (state, action) => {
			state.sidebarExpanded = action.payload;
		},
	},
});

export const { setApiError, clearApiError, setRole, setSidebarExpanded } = appSlice.actions;

export const selectSidebarExpanded = ({ app }) => app.sidebarExpanded;

export default appSlice.reducer;
