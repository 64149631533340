import { ErrorMessage } from '@hookform/error-message';
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormResetField } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectActiveBusiness, selectMemoBusinessStores } from 'src/reducers/business/businessSlice';

import { Select } from '..';
import { SelectOption } from '../Select';

type Props = {
    control: Control<any>;
    name: string;
    onValueChange?: (value: string) => void;
    resetField?: UseFormResetField<FieldValues>;
    resetInput?: string;

    onInputChange?: (inputChange: string) => void;
    isDisabled?: boolean;

    errors?: DeepMap<FieldValues, FieldError>;
};

const SelectLocations: React.FC<Props> = ({ control, name, errors, onValueChange, resetField, resetInput, isDisabled = false }) => {
    const { _id: bid } = useSelector(selectActiveBusiness);
    const storesOptions = useSelector(selectMemoBusinessStores(bid)).map(({ _id, title }) => ({ value: _id, label: title }));

    const selectionValueStore = (selection) => {
        return [{ _id: bid, stores: selection.map((s) => s.value) }];
    };

    const selectedStores = (businesses): SelectOption | Array<SelectOption> => {
        onValueChange && onValueChange(businesses);

        let storesIds = businesses[0]?.stores;
        return storesOptions.filter((o) => storesIds?.includes(o.value));
    };

    const intl = useIntl();

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => {
                    return (
                        <Select
                            value={selectedStores(value)}
                            onChange={(selection) => {
                                onChange(selectionValueStore(selection));
                                resetField && resetInput && resetField(resetInput);
                            }}
                            title={intl.formatMessage({ id: 'USER.MODAL.ACCOUNT.TAB.LOCATION' })}
                            options={storesOptions}
                            isDisabled={isDisabled}
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                        />
                    );
                }}
            />
            {errors && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{message}</span>
                            </div>
                        </div>
                    )}
                />
            )}
        </>
    );
};

export default SelectLocations;
