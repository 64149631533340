import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Table from 'src/components/table/Table';

import useConfirmationModal from '../../../components/modal/useConfirmationModal';
import useModal from '../../../components/modal/useModal';
import TableContext from '../../../components/table/context';
import decoration from '../../../components/table/decoration';
import { DateFormat, formatDate } from '../../../helpers/dates';
import { useRefundPaymentMutation } from '../../../reducers/reservation/reservationApiSlice';

const ReservationPaymentsTable = ({ watch, setValue }) => {
	const { id } = useModal();
	const [refunded, setRefundStatus] = useState(false);
	const paymentIds = watch('payment_ids') ?? [];
	const prepayment = watch('prepayment');
	const payments = watch('payments') ?? [];
	const [confirmAction, confirmationModal] = useConfirmationModal();
	const [refundPayment] = useRefundPaymentMutation();
	const {
		paging: { totalPages },
	} = useContext(TableContext);
	return (
		<div className="mb-5 mb-xl-8">
			<div className="py-2">
				<Table>
					<Table.Column field="amount" formatValue={(value) => `${prepayment?.currency?.symbol ?? '€'} ${value / 100}`}>
						<FormattedMessage id="RESERVATION.PAYMENTS_TABLE.AMOUNT" />
					</Table.Column>
					<Table.Column field="cr_stamp" formatValue={(value) => formatDate(value, DateFormat.SHORT_DATETIME)}>
						<FormattedMessage id="RESERVATION.PAYMENTS_TABLE.CREATED" />
					</Table.Column>
					<Table.Column field="mod_stamp" formatValue={(value) => formatDate(value, DateFormat.SHORT_DATETIME)}>
						<FormattedMessage id="RESERVATION.PAYMENTS_TABLE.UPDATED" />
					</Table.Column>
					<Table.Column
						field="status"
						formatValue={(value) =>
							decoration({ type: 'badge', class: 'badge badge-success fs-6 text-capitalize', payload: refunded ? 'refunded' : value })
						}
					>
						<FormattedMessage id="RESERVATION.PAYMENTS_TABLE.STATUS" />
					</Table.Column>

					<Table.Column
						sticky={true}
						field="action"
						body={(row) => (
							<>
								{payments && payments.find(({ id }) => id === row.id)?.status !== 'refunded' && !refunded ? (
									<Dropdown>
										<Dropdown.Toggle as="button" className="btn btn-sm" id="store_actions_dropdown">
											<i className="bi bi-three-dots-vertical fs-4" />
										</Dropdown.Toggle>
										<Dropdown.Menu style={{ position: 'absolute', zIndex: 1000 }}>
											<Dropdown.Item
												className="btn btn-sm"
												onClick={() => {
													if (!paymentIds.includes(row.id)) {
														confirmAction(() => {
															setValue(`payment_ids[${paymentIds.length++}]`, row.id);
															refundPayment({ path: { id: id }, body: { payment_ids: [row.id] } }).then(() =>
																setRefundStatus(true)
															);
														});
													}
												}}
											>
												<FormattedMessage id="GENERAL.REFUND" />
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								) : null}
							</>
						)}
					>
						<FormattedMessage id="GENERAL.ACTION" />
					</Table.Column>
				</Table>
			</div>

			{totalPages > 1 && (
				<div className="card-footer py-3">
					<Table.Pagination />
				</div>
			)}

			{confirmationModal}
		</div>
	);
};

export default ReservationPaymentsTable;
