import React, { useEffect, useRef } from 'react';

// Helper function to check if an object or array is empty
export const isEmpty = (data: string | { [s: string]: unknown } | ArrayLike<unknown> | object) => {
	return typeof data === 'object'
		? Object.keys(data).length === 0 || Object.values(data).some((element) => element === undefined)
		: Array.isArray(data) && data.length === 0;
};

export const useEscKey = (enable: boolean, callback: () => void) => {
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Enter') {
				callback();
				window.removeEventListener('keydown', handleKeyDown);
			}
		};

		if (enable) {
			return window.addEventListener('keydown', handleKeyDown);
		} else {
			window.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [enable]);
};
