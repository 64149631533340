import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';

import Locales from './Locales';

type TextareaProps = {
	register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
	name: string;
	title?: string;
	placeholder?: string;
	errors?: DeepMap<FieldValues, FieldError>;
	disabled?: boolean;
	localized?: boolean;
	lang?: string;
	setLang?: (lang: string) => void;
};

const Textarea = ({ register, name, title, placeholder = '', errors, disabled = false, localized = false, lang, setLang }: TextareaProps) => {
	return (
		<>
			{title != null && (
				<>
					<label htmlFor={name} className="form-label pb-3 mb-0 fs-7 fw-bold">
						{title}
					</label>
					{localized && <Locales lang={lang!} setLang={setLang!} />}
				</>
			)}
			<textarea
				className={clsx('full-width-textarea fs-6 p-4', { 'mt-3': !title })}
				rows={4}
				cols={50}
				id={name}
				aria-describedby={name}
				placeholder={placeholder}
				disabled={disabled}
				{...register(name)}
			/>
			{errors && (
				<ErrorMessage
					errors={errors}
					name={name}
					render={({ message }) => (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{message}</span>
							</div>
						</div>
					)}
				/>
			)}
		</>
	);
};

export default Textarea;
