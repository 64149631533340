import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Table from 'src/components/table/Table';

import useConfirmationModal from '../../../../../components/modal/useConfirmationModal';

const PaymentsTable = ({ onEditPayment, onDeletePayment }) => {
	const [confirmAction, confirmationModal] = useConfirmationModal();

	return (
		<div className="mb-5 mb-xl-8">
			<div className="py-2">
				<Table hasBorder={true}>
					<Table.Column field="translations.en.title" sortable>
						<FormattedMessage id="LOCATIONS.PAYMENTS_TABLE.RULE" />
					</Table.Column>
					<Table.Column field="type">
						<FormattedMessage id="LOCATIONS.PAYMENTS_TABLE.TYPE" />
					</Table.Column>
					<Table.Column
						field="action"
						body={(row) => (
							<Dropdown>
								<Dropdown.Toggle as="button" className="btn btn-sm" id="store_actions_dropdown">
									<i className="bi bi-three-dots-vertical fs-4" />
								</Dropdown.Toggle>
								<Dropdown.Menu style={{ position: 'absolute', zIndex: 1000 }}>
									<Dropdown.Item className="btn btn-sm" onClick={() => onEditPayment(row._id)}>
										<FormattedMessage id="GENERAL.EDIT" />
									</Dropdown.Item>
									<Dropdown.Item className="btn btn-sm" onClick={() => confirmAction(() => onDeletePayment(row._id))}>
										<FormattedMessage id="GENERAL.DELETE" />
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
					>
						<FormattedMessage id="GENERAL.ACTION" />
					</Table.Column>
				</Table>
				{confirmationModal}
			</div>
		</div>
	);
};

export default PaymentsTable;
