import { Tab } from 'bootstrap';
import { useEffect, useRef } from 'react';

import {
	DrawerComponent,
	MenuComponent,
	ScrollComponent,
	ScrollTopComponent,
	StickyComponent,
	SwapperComponent,
	ToggleComponent,
} from '../assets/ts/components';
import { ThemeModeComponent } from '../assets/ts/layout';
import { useLayout } from './core';

// Init Bootstrap Components outside React
ThemeModeComponent.init();
ToggleComponent.bootstrap();
ScrollTopComponent.bootstrap();
DrawerComponent.bootstrap();
StickyComponent.bootstrap();
ScrollComponent.bootstrap();
SwapperComponent.bootstrap();
document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
	Tab.getOrCreateInstance(tab);
});
MenuComponent.bootstrap();

export function MasterInit() {
	const { config } = useLayout();
	const isFirstRun = useRef(true);
	const pluginsInitialization = () => {
		isFirstRun.current = false;

		setTimeout(() => {}, 500);
	};

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			pluginsInitialization();
		}
	}, [config]);

	return <></>;
}
