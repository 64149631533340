import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { KTSVG } from 'src/_metronic/helpers';

const Dashboard = () => {
	const intl = useIntl();

	// Responsive Dashboard Button changing
	const [activeDashboardTab, setDashboardActiveTab] = useState('overview-tab');
	const [headerDashboardText, setDashboardHeaderText] = useState('REPORTS.OVERVIEW');

	const handleDashboardTabClick = (tabId, headerTextId) => {
		setDashboardActiveTab(tabId);
		setDashboardHeaderText(headerTextId);
	};

	return (
		// <PageTitle breadcrumbs={homeBreadCrumb} description={intl.formatMessage({ id: 'PAGE.DASHBOARD' })}>
		// 	 {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
		// </PageTitle>
		<div className="tab-pane fade show active" id="dashboards" role="tabpanel" aria-labelledby="dashboards-tab">
			<div className="main-container rounded p-4">
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-pills d-flex justify-content-around d-none d-md-flex" id="account-tab" role="tablist">
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'overview-tab' ? 'active' : ''}`}
									id="overview-tab"
									name="overview-tab"
									aria-labelledby="overview-tab"
									data-bs-toggle="pill"
									data-bs-target="#overview"
									type="button"
									role="tab"
									aria-controls="overview"
									aria-selected={activeDashboardTab === 'overview-tab'}
									onClick={() => handleDashboardTabClick('overview-tab', 'REPORTS.OVERVIEW')}
								>
									<FormattedMessage id="REPORTS.OVERVIEW" />
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'lead-analytics-tab' ? 'active' : ''}`}
									id="lead-analytics-tab"
									name="lead-analytics-tab"
									aria-labelledby="lead-analytics-tab"
									data-bs-toggle="pill"
									data-bs-target="#lead-analytics"
									type="button"
									role="tab"
									aria-controls="lead-analytics"
									aria-selected={activeDashboardTab === 'lead-analytics-tab'}
									onClick={() => handleDashboardTabClick('lead-analytics-tab', 'REPORTS.LEAD_ANALYTICS')}
								>
									<FormattedMessage id="REPORTS.LEAD_ANALYTICS" />
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'deal-insights-tab' ? 'active' : ''}`}
									id="deal-insights-tab"
									name="deal-insights-tab"
									aria-labelledby="deal-insights-tab"
									data-bs-toggle="pill"
									data-bs-target="#deal-insights"
									type="button"
									role="tab"
									aria-controls="deal-insights"
									aria-selected={activeDashboardTab === 'deal-insights-tab'}
									onClick={() => handleDashboardTabClick('deal-insights-tab', 'REPORTS.DEAL_INSIGHTS')}
								>
									<FormattedMessage id="REPORTS.DEAL_INSIGHTS" />
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'marketing-metrics-tab' ? 'active' : ''}`}
									id="marketing-metrics-tab"
									name="marketing-metrics-tab"
									aria-labelledby="marketing-metrics-tab"
									data-bs-toggle="pill"
									data-bs-target="#marketing-metrics"
									type="button"
									role="tab"
									aria-controls="marketing-metrics"
									aria-selected={activeDashboardTab === 'marketing-metrics-tab'}
									onClick={() => handleDashboardTabClick('marketing-metrics-tab', 'REPORTS.MARKETING_METRICS')}
								>
									<FormattedMessage id="REPORTS.MARKETING_METRICS" />
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'sales-tab' ? 'active' : ''}`}
									id="sales-tab"
									name="sales-tab"
									aria-labelledby="sales-tab"
									data-bs-toggle="pill"
									data-bs-target="#sales"
									type="button"
									role="tab"
									aria-controls="sales"
									aria-selected={activeDashboardTab === 'sales-tab'}
									onClick={() => handleDashboardTabClick('sales-tab', 'REPORTS.SALES_TRENDS')}
								>
									<FormattedMessage id="REPORTS.SALES_TRENDS" />
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'sales-follow-up-tab' ? 'active' : ''}`}
									id="sales-follow-up-tab"
									name="sales-follow-up-tab"
									aria-labelledby="sales-follow-up-tab"
									data-bs-toggle="pill"
									data-bs-target="#sales-follow-up"
									type="button"
									role="tab"
									aria-controls="sales-follow-up"
									aria-selected={activeDashboardTab === 'sales-follow-up-tab'}
									onClick={() => handleDashboardTabClick('sales-follow-up-tab', 'REPORTS.SALES_FOLLOWUP')}
								>
									<FormattedMessage id="REPORTS.SALES_FOLLOWUP" />
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'activity-stats-tab' ? 'active' : ''}`}
									id="activity-stats-tab"
									name="activity-stats-tab"
									aria-labelledby="activity-stats-tab"
									data-bs-toggle="pill"
									data-bs-target="#activity-stats"
									type="button"
									role="tab"
									aria-controls="activity-stats"
									aria-selected={activeDashboardTab === 'activity-stats-tab'}
									onClick={() => handleDashboardTabClick('activity-stats-tab', 'REPORTS.ACTIVITY_STATS')}
								>
									<FormattedMessage id="REPORTS.ACTIVITY_STATS" />
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className={`nav-link ${activeDashboardTab === 'more-tab' ? 'active' : ''}`}
									id="more-tab"
									name="more-tab"
									aria-labelledby="more-tab"
									data-bs-toggle="pill"
									data-bs-target="#more"
									type="button"
									role="tab"
									aria-controls="more"
									aria-selected={activeDashboardTab === 'more-tab'}
									onClick={() => handleDashboardTabClick('more-tab', 'REPORTS.MORE_TAB')}
								>
									<KTSVG path="/media/icons/duotune/general/gen052.svg" className="svg-icon-muted svg-icon-2x" />
								</button>
							</li>
						</ul>
						<div className="dropdown d-md-none">
							<button
								className="primary-button fs-4 fw-bold w-100 dropdown-toggle"
								type="button"
								id="responsiveMenuDropdown"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<FormattedMessage id={headerDashboardText} />
							</button>
							{/* <ul className="dropdown-menu w-100 mt-4" aria-labelledby="responsiveMenuDropdown">
							 */}
							<ul className="dropdown-menu w-100 mt-4" id="account-tab" aria-labelledby="responsiveMenuDropdown" role="tablist">
								<li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold active"
										href="#overview"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('overview-tab', 'DASHBOARD.OVERVIEW')}
									>
										<FormattedMessage id="DASHBOARD.OVERVIEW" />
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold"
										href="#lead-analytics"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('lead-analytics-tab', 'DASHBOARD.LEAD_ANALYTICS')}
									>
										<FormattedMessage id="DASHBOARD.LEAD_ANALYTICS" />
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold"
										href="#deal-insights"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('deal-insights-tab', 'DASHBOARD.DEAL_INSIGHTS')}
									>
										<FormattedMessage id="DASHBOARD.DEAL_INSIGHTS" />
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold"
										href="#marketing-metrics"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('marketing-metrics-tab', 'DASHBOARD.MARKETING_METRICS')}
									>
										<FormattedMessage id="DASHBOARD.MARKETING_METRICS" />
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold"
										href="#sales"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('sales-tab', 'DASHBOARD.SALES_TRENDS')}
									>
										<FormattedMessage id="DASHBOARD.SALES_TRENDS" />
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold"
										href="#sales-follow-up"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('sales-follow-up-tab', 'DASHBOARD.SALES_FOLLOWUP')}
									>
										<FormattedMessage id="DASHBOARD.SALES_FOLLOWUP" />
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold"
										href="#activity-stats"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('activity-stats-tab', 'DASHBOARD.ACTIVITY_STATS')}
									>
										<FormattedMessage id="DASHBOARD.ACTIVITY_STATS" />
									</a>
								</li>
								{/* <li className="nav-item" role="presentation">
									<a
										className="dropdown-item p-4 fs-5 fw-bold"
										href="#more"
										data-bs-toggle="pill"
										role="tab"
										onClick={() => handleDashboardTabClick('more-tab', 'DASHBOARD.MORE_TAB')}>
										<FormattedMessage id="DASHBOARD.MORE_TAB" />
									</a>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="tab-content" id="reports-dashboards-tab-content">
				<div
					className="tab-pane tab-content fade show active"
					id="reports-dashboards"
					role="tabpanel"
					aria-labelledby="reports-dashboards-tab"
				>
					<div
						className={`tab-pane fade show ${activeDashboardTab === 'overview-tab' ? 'active' : ''}`}
						id="overview"
						role="tabpanel"
						aria-labelledby="overview-tab"
					></div>
					<div
						className={`tab-pane fade ${activeDashboardTab === 'lead-analytics-tab' ? 'active' : ''}`}
						id="lead-analytics"
						role="tabpanel"
						aria-labelledby="lead-analytics-tab"
					></div>
					<div
						className={`tab-pane fade ${activeDashboardTab === 'deal-insights-tab' ? 'active' : ''}`}
						id="deal-insights"
						role="tabpanel"
						aria-labelledby="deal-insights-tab"
					></div>
					<div
						className={`tab-pane fade ${activeDashboardTab === 'marketing-metrics-tab' ? 'active' : ''}`}
						id="marketing-metrics"
						role="tabpanel"
						aria-labelledby="marketing-metrics-tab"
					></div>
					<div
						className={`tab-pane fade ${activeDashboardTab === 'sales-tab' ? 'active' : ''}`}
						id="sales"
						role="tabpanel"
						aria-labelledby="sales-tab"
					></div>
					<div
						className={`tab-pane fade ${activeDashboardTab === 'sales-follow-up-tab' ? 'active' : ''}`}
						id="sales-follow-up"
						role="tabpanel"
						aria-labelledby="sales-follow-up-tab"
					></div>
					<div
						className={`tab-pane fade ${activeDashboardTab === 'activity-stats-tab' ? 'active' : ''}`}
						id="activity-stats"
						role="tabpanel"
						aria-labelledby="activity-stats-tab"
					></div>
					<div
						className={`tab-pane fade ${activeDashboardTab === 'more-tab' ? 'active' : ''}`}
						id="more"
						role="tabpanel"
						aria-labelledby="more-tab"
					></div>
				</div>
			</div>
		</div>
	);
};

export { Dashboard };

// const commTypeOptions = [
// 	{ value: 'mobile', label: 'Mobile' },
// 	{ value: 'email', label: 'Email' },
// 	{ value: 'fax', label: 'Fax' },
// 	{ value: 'phone', label: 'Phone' },
// 	{ value: 'address', label: 'Address' },
// ];

// const DashboardPage = () => {
// 	const { control, resetField } = useForm({
// 		mode: 'onSubmit',
// 	});
// 	useGetAppSectionsQuery({});
// 	const [contactTypes, setContactTypes] = useState([commTypeOptions[1], commTypeOptions[2]]);

// 	return (
// 		<div className="card shadow mb-5 mb-xl-8">
// 			<div className="card-body py-2 px-4">
// 				<div className="row">
// 					<Contacts control={control} />
// 					<ContactStores control={control} resetField={resetField} />

// 					<div className="col col-sm-6">
// 						<Select
// 							value={contactTypes}
// 							onChange={setContactTypes}
// 							title="Select multi with search"
// 							options={commTypeOptions}
// 							defaultValue={commTypeOptions[0]}
// 							isMulti
// 						/>
// 						<div>Choice id: {contactTypes?.map(({ value }) => value + ' ')}</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// const Contacts = ({ control }) => {
// 	const contactsOptions = useSelectQuery({
// 		responseOptions: {
// 			dataName: 'contacts',
// 			idField: '_id',
// 			labelField: 'name',
// 			searchable: true,
// 		},
// 		useQuery: useContactsQuery,
// 	});

// 	return (
// 		<div className="col col-sm-6">
// 			<SelectControl control={control} name="contacts" title={'Search from API'} {...contactsOptions} />
// 			<SelectChoice control={control} field="contacts" />
// 		</div>
// 	);
// };

// const ContactStores = ({ control, resetField }) => {
// 	const contacts = useWatch({
// 		control,
// 		name: 'contacts',
// 	});

// 	const contactOptions = useSelectQuery({
// 		responseOptions: {
// 			dataName: 'contact.stores',
// 			idField: '_id',
// 			labelField: 'type',
// 			path: { id: contacts },
// 			skip: !contacts,
// 		},
// 		useQuery: useContactQuery,
// 	});

// 	useEffect(() => {
// 		resetField('contact');
// 	}, [contacts]);

// 	return (
// 		<div className="col col-sm-6">
// 			<SelectControl control={control} name="contact" title={'Search from API'} {...contactOptions} />

// 			<SelectChoice control={control} field="contact" />
// 		</div>
// 	);
// };

// function SelectChoice({ control, field }) {
// 	const choice = useWatch({
// 		control,
// 		name: field, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
// 		defaultValue: 'default', // default value before the render
// 	});

// 	return <div>Choice : {choice}</div>; // only re-render at the custom hook level, when firstName changes
// }
