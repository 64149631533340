import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getToolbarActions } from '../../../../reducers/toolbarSlice';

const FilterAction = ({ filtersMenu }) => {
	const intl = useIntl();

	const filters = filtersMenu();
	return (
		<div className="m-0">
			<a href="#" className="secondary-button fs-7 fw-bold" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
				{intl.formatMessage({ id: 'GENERAL.FILTERS' })}
			</a>
			<div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
				{filters}
			</div>
		</div>
	);
};

const ToolbarActions = () => {
	const toolbarActions = useSelector(getToolbarActions);

	return (
		<>
			{toolbarActions.map((action) => {
				if (action.type === 'filterAction') {
					return <FilterAction key={action?.id} filtersMenu={action?.filtersMenu} />;
				}
				return (
					<button key={action.id} type="button" className={action?.className} onClick={action.onClick}>
						{action?.icon}
						{action.label}
					</button>
				);
			})}
		</>
	);
};

export default ToolbarActions;
