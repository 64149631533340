export const customStyles = {
	control: (base, state) => ({
		...base,
		padding: '.25rem',
		border: 'none',
		background: '#f9f9f9',
		minHeight: '3.6rem',
		// match with the menu
		borderRadius: state.isFocused ? '5px' : 5,
		// Overwrittes the different states of border
		borderColor: state.isFocused ? 'none' : 'none',
		// Removes weird border around container
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: state.isFocused ? 'none' : 'none',
		},
	}),
	menu: (base) => ({
		...base,
		// override border radius to match the box
		borderRadius: 0,
		// kill the gap
		marginTop: 0,
	}),
	menuList: (base) => ({
		...base,
		// kill the white space on first and last option
		padding: 0,
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#ededed',
			color: '#373737',
			borderRadius: '3px',
			padding: '.25rem 0',
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		fontSize: '1em',
		fontWeight: '600',
		borderRadius: '5px',
		color: '#373737',
		padding: '.25rem 0',
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		'&:hover': {
			backgroundColor: '#ededed',
		},
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isFocused ? '#ededed' : null,
			color: '#373737',
		};
	},
};

export const mainCustomStyles = {
	control: (base, state) => ({
		...base,
		padding: '0.23rem',
		border: '1px solid #e4e6ef',
		background: 'transparent',
		// minHeight: '3.6em',
		// match with the menu
		borderRadius: state.isFocused ? '5px' : 5,
		// Overwrittes the different states of border
		borderColor: state.isFocused ? 'none' : 'none',
		// Removes weird border around container
		boxShadow: state.isFocused ? null : null,
		'&:hover': {
			// Overwrittes the different states of border
			borderColor: state.isFocused ? 'none' : 'none',
		},
	}),
	menu: (base) => ({
		...base,
		// override border radius to match the box
		borderRadius: 0,
		// kill the gap
		marginTop: 0,
	}),
	menuList: (base) => ({
		...base,
		// kill the white space on first and last option
		padding: 0,
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#ededed',
			color: '#373737',
			borderRadius: '3px',
			padding: '.25rem 0',
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		fontSize: '1em',
		fontWeight: '600',
		borderRadius: '5px',
		color: '#373737',
		padding: '.25rem 0',
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		'&:hover': {
			backgroundColor: '#ededed',
		},
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isFocused ? '#ededed' : null,
			color: '#373737',
		};
	},
};
