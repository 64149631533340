import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { useCheckReadPermissions } from 'src/components/permissions/useCheckPermissions';
import { RestrictedView } from 'src/components/RestrictedView';

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import MasterLayout from '../../_metronic/layout/MasterLayout';
import { Dashboard } from '../../pages/Dashboard';

const PrivateRoutes = () => {
	const Business = lazy(() => import('../../pages/business/Business'));
	const ReservationsList = lazy(() => import('../../pages/reservations/List'));
	const ReservationEventsList = lazy(() => import('../../pages/reservations/events/EventsList'));
	const Contacts = lazy(() => import('../../pages/contacts/contact/Contact'));
	const Elenxis = lazy(() => import('../../pages/elenxis/Elenxis'));
	const Feedback = lazy(() => import('../../pages/marketing/feedback/Feedback'));
	const Coupons = lazy(() => import('../../pages/marketing/coupon/Coupons'));
	const Users = lazy(() => import('../../pages/settings/users/user/User'));
	const RolesList = lazy(() => import('../../pages/settings/users/roles/RolesList'));
	const Stores = lazy(() => import('../../pages/settings/stores/Store'));
	const Settings = lazy(() => import('../../pages/settings/account/Settings'));
	const Activity = lazy(() => import('../../pages/settings/account/Activity'));
	const Logs = lazy(() => import('../../pages/settings/account/Logs'));
	const Area = lazy(() => import('../../pages/settings/areas/Area'));
	const Event = lazy(() => import('../../pages/events/Events'));
	const Category = lazy(() => import('../../pages/events/Categories'));
	const Ticket = lazy(() => import('../../pages/events/Tickets'));
	const Tag = lazy(() => import('../../pages/analytics/tag/Tag'));
	const TagCategory = lazy(() => import('../../pages/analytics/tag/TagCategory'));
	const Playground = lazy(() => import('../../pages/developer/playground'));
	const HostView = lazy(() => import('../../pages/host-view/pages/HostView'));
	const Widget = lazy(() => import('../../pages/settings/Widget'));

	const routes = [
		{
			path: 'auth/*',
			element: <Navigate to="/dashboard" />,
			permissions: false,
			readMode: useCheckReadPermissions('auth/*'),
		},
		{
			path: 'dashboard',
			element: <Dashboard />,
			permissions: false,
			readMode: useCheckReadPermissions('dashboard'),
		},
		{
			path: 'reservations/list',
			element: <ReservationsList />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('reservations/list'),
		},
		{
			path: 'reservations/events',
			element: <ReservationEventsList />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('reservations/events'),
		},
		{
			path: 'reservations/hostview',
			element: <HostView />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('reservations/hostview'),
		},
		{
			path: 'events/list',
			element: <Event />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('events/list'),
		},
		{
			path: 'events/categories',
			element: <Category />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('events/categories'),
		},
		{
			path: 'events/tickets',
			element: <Ticket />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('events/tickets'),
		},
		{
			path: 'contacts/list',
			element: <Contacts type="contacts" />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('contacts/list'),
		},
		{
			path: 'contacts/customers',
			element: <Contacts type="customers" />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('contacts/customers'),
		},
		{
			path: 'contacts/leads',
			element: <Contacts type="leads" />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('contacts/leads'),
		},
		{
			path: 'vac',
			element: <Elenxis />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('vac'),
		},
		{
			path: 'settings/tags/list',
			element: <Tag />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/tags/list'),
		},
		{
			path: 'settings/tags/categories',
			caseSensitive: true,
			element: <TagCategory />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/tags/categories'),
		},
		{
			path: 'marketing/feedback',
			element: <Feedback />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('marketing/feedback'),
		},
		{
			path: 'marketing/coupons',
			element: <Coupons />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('marketing/coupons'),
		},
		{
			path: 'settings/business',
			element: <Business />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/business'),
		},
		{
			path: 'settings/users/list',
			element: <Users />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/users/list'),
		},
		{
			path: 'settings/users/roles',
			element: <RolesList />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/users/roles'),
		},
		{
			path: 'settings/stores',
			element: <Stores />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/stores'),
		},
		{
			path: 'settings/account/settings',
			element: <Settings />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/account/settings'),
		},
		{
			path: 'settings/account/activity',
			element: <Activity />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/account/activity'),
		},
		{
			path: 'settings/account/logs',
			element: <Logs />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/account/logs'),
		},
		{
			path: 'settings/areas',
			element: <Area />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/areas'),
		},
		{
			path: 'settings/widget',
			element: <Widget />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('settings/widget'),
		},
		{
			path: 'playground',
			element: <Playground />,
			permissions: true,
			suspense: true,
			readMode: useCheckReadPermissions('playground'),
		},
		{
			path: '*',
			element: <Navigate to="/error/404" />,
			readMode: useCheckReadPermissions('*'),
		},
	];

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{routes.map((route, i) => {
					const isLoading = route.readMode.isLoading;
					const canRead = route.permissions ? route.readMode.hasAccess : true;
					const element = route.suspense ? <SuspensedView>{route.element}</SuspensedView> : route.element;

					return (
						<Route
							key={i}
							caseSensitive={route.caseSensitive ?? false}
							path={route.path}
							element={isLoading ? <div></div> : canRead ? element : <RestrictedView />}
						/>
					);
				})}
			</Route>
		</Routes>
	);
};

const SuspensedView = ({ children }) => {
	const baseColor = getCSSVariableValue('--kt-primary');
	TopBarProgress.config({
		barColors: {
			0: baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	});
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default PrivateRoutes;
