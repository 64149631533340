import clsx from 'clsx';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { PhoneNumber, SelectControl, Text } from '../';
import { KTSVG } from '../../../_metronic/helpers';

const ContactInfo = ({ control, register, name, errors, watch, setValue, disabled = false, title = null }) => {
	const intl = useIntl();

	const commTypeOptions = [
		{ value: 'mobile', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.MOBILE' }) },
		{ value: 'email', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.EMAIL' }) },
		{ value: 'fax', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.FAX' }) },
		{ value: 'phone', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.PHONE' }) },
		{ value: 'address', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.ADDRESS' }) },
	];

	const commLabelOptions = [
		{ value: 'personal', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.PERSONAL' }) },
		{ value: 'business', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.BUSINESS' }) },
		{ value: 'other', label: intl.formatMessage({ id: 'COMMUNICATION_DETAILS.TYPE.OTHER' }) },
	];

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const appendItem = () => append({ type: commTypeOptions[0].value });

	const shouldDisplay = !(fields.length === 0 && disabled);
	return (
		<>
			{shouldDisplay && (
				<>
					{title && <div className="mb-0 fs-2 pb-5 fw-bold">{title}</div>}
					{fields.map((field, index) => {
						const type = watch(`${name}.${index}.type`) || commTypeOptions[0].value;
						return (
							<div className="input-group" key={field.id}>
								<div className="row pb-5">
									<div className="col-12 col-sm-6">
										<div className="row">
											<div className="col-12 col-sm-6">
												<SelectControl
													control={control}
													title={intl.formatMessage({ id: 'CONTACT.DETAILS.TAB.COMMUNICATION' })}
													name={`${name}.${index}.type`}
													options={commTypeOptions}
													defaultValue={commTypeOptions[0]}
													isDisabled={disabled}
												/>
											</div>
											<div className="col-12 col-sm-6 pt-5 pt-sm-0">
												<SelectControl
													control={control}
													title={intl.formatMessage({ id: 'CONTACT.DETAILS.TAB.TYPE' })}
													name={`${name}.${index}.label`}
													options={commLabelOptions}
													defaultValue={commLabelOptions[0]}
													isDisabled={disabled}
												/>
											</div>
										</div>
									</div>
									<div className="col-12 col-sm-6 d-flex align-items-end">
										<div className="row flex-fill">
											<div className="col-12 col-sm-8 pt-5 pt-sm-0">
												{['mobile', 'phone', 'fax'].includes(type) && (
													<PhoneNumber
														control={control}
														name={`${name}.${index}.val`}
														title={'\u00A0'}
														errors={errors}
														disabled={disabled}
													/>
												)}
												{!['mobile', 'phone', 'fax'].includes(type) && (
													<Text
														register={register}
														name={`${name}.${index}.val`}
														title={'\u00A0'}
														errors={errors}
														disabled={disabled}
													/>
												)}
											</div>
											{!disabled && (
												<div
													className={clsx('col-12 col-sm-4 pt-5 pt-sm-0 d-flex', {
														'align-items-center': errors && Object.keys(errors).length > 0,
														'align-items-end': !errors || Object.keys(errors).length === 0,
													})}>
													<button
														onClick={() => remove(index)}
														type="button"
														className="btn  btn-light-danger btn-color-danger w-100 d-flex justify-content-center align-items-center">
														<KTSVG className="svg-icon-1" path="/media/icons/duotune/general/gen027.svg" />
														<div>
															<FormattedMessage id="GENERAL.DELETE" />
														</div>
													</button>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					})}

					{!disabled && (
						<div className="row ">
							<div className="col-12 col-sm-3">
								<button type="button" className="generic-button fw-bold fs-6 w-100 p-4" onClick={appendItem}>
									<FormattedMessage id="GENERAL.ADD" />
								</button>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default ContactInfo;
