import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { logOut } from '../../../../reducers/auth/slice';

const HeaderUserMenu = () => {
	const dispatch = useDispatch();

	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
			data-kt-menu="true"
		>
			<div className="menu-item px-5">
				<a href="/settings/account/settings" className="menu-link px-5">
					<FormattedMessage id="HEADER.MENU.USER.ACCOUNT" />
				</a>
			</div>
			<div className="menu-item px-5">
				<a onClick={() => dispatch(logOut())} className="menu-link px-5">
					<FormattedMessage id="HEADER.MENU.USER.SIGN_OUT" />
				</a>
			</div>
		</div>
	);
};

export { HeaderUserMenu };
