import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from 'src/_metronic/helpers';

type SvgIconProps = {
	path: string;
	className?: string;
	svgClassName?: string;
	onClick?: () => void;
};

const SvgIcon = ({ path, className, svgClassName = 'mh-50px', onClick }: SvgIconProps) => (
	<span className={clsx('svg-icon', className)} onClick={onClick}>
		<SVG src={toAbsoluteUrl(path)} className={svgClassName} />
	</span>
);

export default SvgIcon;
